import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {
    BARCODE_DIGITS, BARCODE_LENGTH,
    BARCODE_TYPE,
    PRODUCT_TYPE,
    PRODUCT_UNIT,
    STATUS, STOCK_EVENT, STOCK_EVENT_ADD_REASON, STOCK_EVENT_DROP_REASON,
    TILL_IMAGE,
    WEB_MENU_SMALL_IMAGE
} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {Checkbox} from "primereact/checkbox";
import {InputNumber} from "primereact/inputnumber";
import {FileUpload} from "primereact/fileupload";
import {Button as ButtonSearch} from "primereact/components/button/Button";
import DepartmentService from "../../shared/service/DepartmentService";
import TaxService from "../../shared/service/TaxService";
import ProductService from "../../shared/service/ProductService";
import {MultiSelect} from "primereact/multiselect";
import TagService from "../../shared/service/TagService";
import CountryService from "../../shared/service/CountryService";
import ProductClassService from "../../shared/service/ProductClassService";
import SupplierService from "../../shared/service/SupplierService";
import Decimal from "./decimal.mjs";
import {AutoComplete} from "primereact/autocomplete";

const defaultProduct = {
    id: '',
    name: '',
    status: 'ACTIVE',
    barcode: '',
    departmentId: null,
    departmentProfitMargin: null,
    departmentTaxId: null,
    tax: null,
    price: null,
    oldPrice: null,
    webPrice: null,
    price2: null,
    price3: null,
    unit: null,
    quantity: 1,
    weighable: false,
    allowZeroPrice: false,
    allowNegativePrice: false,
    productType: 'PRODUCT_LINE',
    fileType: '',
    fileName: 'Choose',
    fileTypeTill: '',
    fileNameTill: 'Choose',
    productTags: [],
    mixAndMatchName: '',
    minStock: null,
    maxStock: null,
    currentStock: null,
    stockOperation: '',
    stockOperationReason: '',
    stock: null,
    supplierId: null,
    countryId: null,
    productClassId: null,
    description: '',
    rowNumber: 1000,
    colNo: 10,
    costPrice: 0,
    boxQuantity: null,
    boxUnit: 'each',
    profitMargin: null,
    boxPrice: 0,
    unitCost: 0,
    actualMargin: null,
    retailPrice: 0,
    codeLength: 7,
    webEnabled: true
};


export default class ProductSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: defaultProduct,
            productTemp: defaultProduct,
            productDetailsSaveButtonRender: false,
            stockDetailsSaveButtonRender: false,
            defaultStatus: 'ACTIVE',
            taxList: [],
            tagList: [],
            departmentList: [],
            countryList: [],
            productClassList: [],
            supplierList: [],
            filteredProducts: []
        }
        this.productService = new ProductService();
        this.departmentService = new DepartmentService();
        this.taxService = new TaxService();
        this.productTagService = new TagService();
        this.countryService = new CountryService();
        this.productClassService = new ProductClassService();
        this.supplierService = new SupplierService();
    }

    componentDidMount() {

        let id = window.location.href.split('?')[1];
        if (!GeneralUtils.__isValueEmpty(id)) {
            this.productService.findById(id).then(response => this.setState({
                product: response,
                productTemp: Object.assign({}, response)
            }));
            this.calculateFields();
        } else {
            let obj1 = Object.assign({}, defaultProduct);
            this.setState({
                product: obj1,
                productDetailsSaveButtonRender: true,
                stockDetailsSaveButtonRender: true,
                productTemp: Object.assign({}, obj1)
            });

        }

        this.taxService.findAll().then(response => this.setState({taxList: response}));
        this.departmentService.findAll().then(response => this.setState({departmentList: response}));
        this.productTagService.findAllActive().then(response => this.setState({tagList: response}));
        this.countryService.findAll().then(response => this.setState({countryList: response}));
        this.productClassService.findAll().then(response => this.setState({productClassList: response}));
        this.supplierService.findAll().then(response => this.setState({supplierList: response}));

    }

    render() {
        return (<div>
            <AvForm autocomplete="off">
                {this.productDetails()}
            </AvForm>
        </div>);
    }


    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            this.productService.updateStatus(this.state.product.id, this.state.defaultStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            product: response,
                            productDetailsSaveButtonRender: false,
                            stockDetailsSaveButtonRender: false,
                            productTemp: Object.assign({}, response)
                        });
                    }
                });
        } else {
            let product = this.state.product;
            product["status"] = this.state.product.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({product: product, productTemp: Object.assign({}, product)});
        }

        return undefined;
    }


    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }
    setChecked = (name, value) => {
        let product = this.state.product;
        product[name] = value;
        this.setState({product: product});
    }

    downloadDocument = (type) => {
        if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            this.productService.downloadDocument(this.state.product.id, type).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.convertToDownloadableFile(response);
                }
            });
        }
    }

    productDetails() {

        return (<div>
            <Fieldset legend="Product Details">
                <Row>
                    <Col md="9">
                        <Row>
                            <Col md="3">
                                <Fieldset legend="Status">
                                    <Col md="3">
                                    </Col>
                                    <Col md="9">
                                        <Row>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="status"
                                                disabled={true}
                                                value={this.state.product.status}
                                                onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>


                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                          show={this.state.displayShowStatusConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.deleteProduct}
                                                          onCancelClick={() => this.setState({displayDeleteConfirmDialog: false})}
                                                          show={this.state.displayDeleteConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <InputSwitch checked={this.state.product.status == "ACTIVE" ? true : false}
                                                         tooltip={"Change Status"}
                                                         disabled={!this.state.productDetailsSaveButtonRender}
                                                         onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                        </Row>

                                    </Col>

                                </Fieldset>

                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Barcode</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="number"
                                        className="form-control"
                                        name="barcode"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        max="99999999999999"
                                        value={this.state.product.barcode}
                                        onChange={this.__handleChange}/>


                                </AvGroup>
                            </Col>

                            <Col md="3">
                                {(this.state.product.id == "" || this.state.product.id == null || GeneralUtils.isNullOrEmpty(this.state.product.barcode)) && this.state.productDetailsSaveButtonRender &&
                                    <Button color="primary"
                                            style={{width: '100%'}}
                                            onClick={() => {
                                                this.productService.getNextBarcode().then(response => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                        let product = this.state.product;
                                                        product["barcode"] = response.barcode;
                                                        this.setState({product: product});
                                                    }

                                                });

                                            }}>
                                        <Translate contentKey="entity.action.add">Generate Barcode</Translate>
                                    </Button>}
                            </Col>

                            {this.state.product.barcode != null && this.state.product.barcode.length >= 12 &&
                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Barcode Type</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="barcodeType"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.barcodeType}
                                            onChange={this.__handleChange}>
                                            {BARCODE_TYPE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            }

                            {this.state.product.barcode != null && this.state.product.barcode.length >= 12 &&
                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Barcode Digit</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="barcodeDigit"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.barcodeDigit}
                                            onChange={this.__handleChange}>
                                            {BARCODE_DIGITS.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            }

                            {this.state.product.barcode != null && this.state.product.barcode.length >= 12 &&
                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Code Length</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="codeLength"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.codeLength}
                                            onChange={this.__handleChange}>
                                            {BARCODE_LENGTH.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            }


                        </Row>
                        <Row>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AutoComplete value={this.state.product.name}
                                                  suggestions={this.state.filteredProducts}
                                                  completeMethod={this.searchProduct}
                                                  field="name"
                                                  disabled={!this.state.productDetailsSaveButtonRender}
                                                  onChange={(e) => {
                                                      if (!GeneralUtils.isNullOrEmpty(e.value.id)) {
                                                          this.setState({selectedProduct: e.value});
                                                          let data = e.value;
                                                          this.setState({
                                                              product: data,
                                                          });
                                                      }

                                                  }
                                                  }

                                    />


                                </AvGroup>
                            </Col>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Quantity</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <InputNumber
                                        name="quantity"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        value={this.state.product.quantity}
                                        onChange={this.__handleChange}
                                        className="table-responsive"
                                        locale="en-US"
                                    />

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Unit</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="unit"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        value={this.state.product.unit}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {PRODUCT_UNIT.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Weighable&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("weighable", e.checked)}
                                              name="weighable"
                                              disabled={!this.state.productDetailsSaveButtonRender}
                                              value={this.state.product.weighable}
                                              checked={this.state.product.weighable}></Checkbox>


                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Department</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        className="form-control"
                                        name="departmentId"
                                        value={this.state.product.departmentId}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {this.state.departmentList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Tax</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        className="form-control"
                                        name="tax"
                                        style={this.state.product.tax != null && this.state.product.departmentTaxId != this.state.product.tax ? {color: 'red'} : {color: 'black'}}
                                        value={this.state.product.tax}
                                        onChange={this.__handleChange}>

                                        <option value='' key=''/>
                                        {this.state.taxList.map(element => (<option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="cityId">
                                        <Translate
                                            contentKey="location.cityId">Product Tag</Translate>
                                    </Label>
                                    <MultiSelect
                                        value={this.state.product.productTags}
                                        options={this.state.tagList}
                                        name="productTags"
                                        disabled={!this.state.productDetailsSaveButtonRender}
                                        onChange={this.__handleChange}
                                        optionLabel="name"
                                        placeholder="Select a Product Tag" maxSelectedLabels={4}/>

                                </AvGroup>
                            </Col>



                        </Row>

                        <Fieldset legend={"Price"}>
                            <Row>
                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Till Price</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>

                                        <InputNumber
                                            name="price"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.price}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Price 2</Translate>
                                        </Label>

                                        <InputNumber
                                            name="price2"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.price2}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Price 3</Translate>
                                        </Label>

                                        <InputNumber
                                            name="price3"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.price3}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>


                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Old Price</Translate>
                                        </Label>
                                        <InputNumber
                                            name="oldPrice"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.oldPrice}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />

                                    </AvGroup>
                                </Col>


                                    <Col md="3">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="producttradingName">Web Price</Translate>
                                            </Label>
                                            <InputNumber
                                                name="webPrice"
                                                disabled={true}
                                                value={this.state.product.webPrice}
                                                onChange={this.__handleChange}
                                                mode="currency"
                                                className="table-responsive"
                                                currency="GBP" locale="en-US"
                                            />

                                        </AvGroup>

                                    </Col>
                                    <Col md="3">
                                        {GeneralUtils.checkPrivilege("put_product/product/all") &&
                                            <Button color="outline-primary" style={{marginRight: '5px'}}
                                                    onClick={() => {
                                                        this.calculateWebPrice()
                                                    }}>
                                                <Translate contentKey="entity.action.add">Calculate Web Price</Translate>
                                            </Button>}
                                    </Col>
                            </Row>
                        </Fieldset>


                        <Fieldset legend={"Stock Management"}>
                            <Row>


                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Current Stock</Translate>
                                        </Label>
                                        <InputNumber
                                            name="currentStock"
                                            disabled={true}
                                            value={this.state.product.currentStock}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Min Stock</Translate>
                                        </Label>
                                        <InputNumber
                                            name="minStock"
                                            disabled={!this.state.stockDetailsSaveButtonRender}
                                            value={this.state.product.minStock}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Max Stock</Translate>
                                        </Label>
                                        <InputNumber
                                            name="maxStock"
                                            disabled={!this.state.stockDetailsSaveButtonRender}
                                            value={this.state.product.maxStock}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Stock Operation</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="stockOperation"
                                            disabled={!this.state.stockDetailsSaveButtonRender}
                                            value={this.state.product.stockOperation}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {STOCK_EVENT.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>

                                {this.state.product.stockOperation == "ADD_STOCK" &&
                                    <Col md="2">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="producttradingName">Stock Operation
                                                    Reason</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="stockEventReason"
                                                disabled={!this.state.stockDetailsSaveButtonRender}
                                                value={this.state.product.stockEventReason}
                                                onChange={this.__handleChange}>
                                                {STOCK_EVENT_ADD_REASON.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                }

                                {this.state.product.stockOperation == "DROP_STOCK" &&
                                    <Col md="2">
                                        <AvGroup>
                                            <Label for="tradingName">
                                                <Translate contentKey="producttradingName">Stock Operation
                                                    Reason</Translate>
                                            </Label>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="stockEventReason"
                                                disabled={!this.state.stockDetailsSaveButtonRender}
                                                value={this.state.product.stockEventReason}
                                                onChange={this.__handleChange}>
                                                {STOCK_EVENT_DROP_REASON.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>
                                        </AvGroup>
                                    </Col>
                                }


                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Stock</Translate>
                                        </Label>
                                        <InputNumber
                                            name="stock"
                                            disabled={!this.state.stockDetailsSaveButtonRender}
                                            value={this.state.product.stock}
                                            onChange={this.__handleChange}
                                            className="table-responsive"
                                        />
                                    </AvGroup>
                                </Col>


                            </Row>
                            {!GeneralUtils.isNullOrEmpty(this.state.product.id) && GeneralUtils.checkPrivilege("post_product/product") && this.state.stockDetailsSaveButtonRender == false &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                    this.setState({
                                        productDetailsSaveButtonRender: false,
                                        stockDetailsSaveButtonRender: true
                                    });
                                }}>
                                    <Translate contentKey="entity.action.add">Edit Stock</Translate>
                                </Button>}

                            {!GeneralUtils.isNullOrEmpty(this.state.product.id) && GeneralUtils.checkPrivilege("post_product/product") && this.state.stockDetailsSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                    this.updateStock();
                                }}>
                                    <Translate contentKey="entity.action.add">Save Stock</Translate>
                                </Button>
                            }

                            {!GeneralUtils.isNullOrEmpty(this.state.product.id) && GeneralUtils.checkPrivilege("post_product/product") && this.state.stockDetailsSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                    let obj1 = this.state.productTemp;

                                    this.setState({
                                        product: Object.assign({}, obj1),
                                        productDetailsSaveButtonRender: false,
                                        stockDetailsSaveButtonRender: false
                                    });
                                }}>
                                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                </Button>

                            }


                        </Fieldset>

                        <Fieldset legend={"Promotions"}>
                            <Row>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Delivery</Translate>
                                        </Label>
                                        <InputNumber
                                            name="price3"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.price3}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Promotions</Translate>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="mixAndMatchName"
                                            disabled={true}
                                            value={this.state.product.mixAndMatchName}
                                            onChange={this.__handleChange}
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="2">

                                    <AvGroup>
                                        <Label for="firstName">
                                            <Translate contentKey="productfirstName">Zero Price&nbsp;</Translate>
                                        </Label>

                                        <Checkbox onChange={(e) => this.setChecked("allowZeroPrice", e.checked)}
                                                  name="allowZeroPrice"
                                                  disabled={!this.state.productDetailsSaveButtonRender}
                                                  value={this.state.product.allowZeroPrice}
                                                  checked={this.state.product.allowZeroPrice}></Checkbox>
                                    </AvGroup>
                                </Col>


                                <Col md="2">
                                    <AvGroup>
                                        <Label for="firstName">
                                            <Translate contentKey="productfirstName">Negative
                                                Price&nbsp;</Translate>
                                        </Label>

                                        <Checkbox onChange={(e) => this.setChecked("allowNegativePrice", e.checked)}
                                                  name="allowNegativePrice"
                                                  disabled={!this.state.productDetailsSaveButtonRender}
                                                  value={this.state.product.allowNegativePrice}
                                                  checked={this.state.product.allowNegativePrice}></Checkbox>


                                    </AvGroup>
                                </Col>

                                <Col md="2">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Product Type</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="productType"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.productType}
                                            onChange={this.__handleChange}>
                                            {PRODUCT_TYPE.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            </Row>
                        </Fieldset>

                    </Col>


                    <Col md="3">
                        <Row>
                            <Col md={6}>
                                <Fieldset legend="Image(Web)">
                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    accept="image/*"
                                                    maxFileSize={50000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 50K."
                                                    chooseLabel={this.state.product.fileName == null ? "Choose" : this.state.product.fileName}
                                                    disabled={!this.state.productDetailsSaveButtonRender}
                                                    ref={elem => this.refUploadInputImage = elem}
                                                    mode="basic"></FileUpload>

                                        <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}} onClick={(e) => {
                                            if (e.detail == 1) {//sayfada enter e basınca resim acılmasın diye
                                                try {
                                                    GeneralUtils.previewDocument(this.refUploadInputImage)
                                                } catch (e) {
                                                    this.downloadDocument(WEB_MENU_SMALL_IMAGE);
                                                }
                                            }

                                        }}>
                                        </ButtonSearch>

                                        <ButtonSearch icon="pi pi-minus" disabled={!this.state.productDetailsSaveButtonRender}
                                                      style={{marginLeft: '5px'}} onClick={(e) => {
                                            this.removeDocument(WEB_MENU_SMALL_IMAGE)
                                        }}>
                                        </ButtonSearch>
                                    </Row>
                                </Fieldset>
                            </Col>
                            <Col md={6}>
                                <Fieldset legend="Image(Till)">
                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    maxFileSize={50000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 50K."
                                                    accept="image/*"
                                                    chooseLabel={this.state.product.fileNameTill == null ? "Choose" : this.state.product.fileNameTill}
                                                    disabled={!this.state.productDetailsSaveButtonRender}
                                                    ref={elem => this.refUploadInputImageTill = elem}
                                                    mode="basic"></FileUpload>

                                        <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}} onClick={(e) => {
                                            if (e.detail == 1) {//sayfada enter e basınca resim acılmasın diye
                                                try {
                                                    GeneralUtils.previewDocument(this.refUploadInputImageTill)
                                                } catch (e) {
                                                    this.downloadDocument(TILL_IMAGE);
                                                }
                                            }

                                        }}>
                                        </ButtonSearch>

                                        <ButtonSearch icon="pi pi-minus" disabled={!this.state.productDetailsSaveButtonRender}
                                                      style={{marginLeft: '5px'}} onClick={(e) => {
                                            this.removeDocument(TILL_IMAGE)
                                        }}>
                                        </ButtonSearch>
                                    </Row>
                                </Fieldset>
                            </Col>
                        </Row>
                        <Fieldset legend={"Price Calculator"}>
                            <Row>


                                <Col md="6">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate contentKey="contactManagement.mobilePhone">Supplier</Translate>
                                        </Label>

                                        <AvField
                                            type="select"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            className="form-control"
                                            name="supplierId"
                                            value={this.state.product.supplierId}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {this.state.supplierList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>

                                    </AvGroup>
                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Box Cost</Translate>
                                        </Label>

                                        <InputNumber
                                            name="costPrice"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.costPrice}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="6">

                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Box Quantity</Translate>
                                        </Label>

                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="boxQuantity"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.boxQuantity}
                                            onChange={this.__handleChange}>

                                        </AvField>
                                    </AvGroup>

                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Box Unit</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="boxUnit"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.boxUnit}
                                            onChange={this.__handleChange}>
                                            {PRODUCT_UNIT.map(element => (
                                                <option value={element.value} key={element.value}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>

                                <Col md="6">

                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Profit Margin</Translate>
                                        </Label>

                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="profitMargin"
                                            style={this.state.product.departmentProfitMargin != this.state.product.profitMargin ? {color: 'red'} : {color: 'black'}}
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.profitMargin}
                                            onChange={this.__handleChange}>

                                        </AvField>
                                    </AvGroup>

                                </Col>


                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Box Price</Translate>
                                        </Label>

                                        <InputNumber
                                            name="boxPrice"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.boxPrice}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Unit Cost</Translate>
                                        </Label>

                                        <InputNumber
                                            name="unitCost"
                                            disabled={true}
                                            value={this.state.product.unitCost}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>


                                <Col md="6">

                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Actual Margin</Translate>
                                        </Label>

                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="actualMargin"
                                            disabled={true}
                                            value={this.state.product.actualMargin}
                                            onChange={this.__handleChange}>

                                        </AvField>
                                    </AvGroup>

                                </Col>


                                <Col md="6">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="producttradingName">Retail Price</Translate>
                                        </Label>

                                        <InputNumber
                                            name="retailPrice"
                                            disabled={true}
                                            value={this.state.product.retailPrice}
                                            onChange={this.__handleChange}
                                            mode="currency"
                                            className="table-responsive"
                                            currency="GBP" locale="en-US"
                                        />
                                    </AvGroup>
                                </Col>

                            </Row>

                        </Fieldset>
                        <Fieldset legend={"Till"}>
                            <Row>
                                <Col md="6">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate contentKey="contactManagement.mobilePhone">Class</Translate>
                                        </Label>

                                        <AvField
                                            type="select"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            className="form-control"
                                            name="productClassId"
                                            value={this.state.product.productClassId}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {this.state.productClassList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>

                                    </AvGroup>
                                </Col>

                                <Col md="6">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate contentKey="contactManagement.mobilePhone">Origin</Translate>
                                        </Label>

                                        <AvField
                                            type="select"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            className="form-control"
                                            name="countryId"
                                            value={this.state.product.countryId}
                                            onChange={this.__handleChange}>
                                            <option value='' key=''/>
                                            {this.state.countryList.map(element => (
                                                <option value={element.id} key={element.id}>
                                                    {element.name}
                                                </option>))}
                                        </AvField>

                                    </AvGroup>
                                </Col>


                                <Col md="6">

                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Row</Translate>
                                        </Label>

                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="rowNumber"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.rowNumber}
                                            onChange={this.__handleChange}>

                                        </AvField>
                                    </AvGroup>

                                </Col>

                                <Col md="6">

                                    <AvGroup>
                                        <Label for="cityId">
                                            <Translate
                                                contentKey="location.cityId">Col</Translate>
                                        </Label>

                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="colNo"
                                            disabled={!this.state.productDetailsSaveButtonRender}
                                            value={this.state.product.colNo}
                                            onChange={this.__handleChange}>

                                        </AvField>
                                    </AvGroup>

                                </Col>

                            </Row>

                        </Fieldset>


                    </Col>


                </Row>


                {GeneralUtils.checkPrivilege("post_product/product") && this.state.productDetailsSaveButtonRender == false &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({productDetailsSaveButtonRender: true, stockDetailsSaveButtonRender: false});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/product") && this.state.productDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveProduct("save");
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("post_product/product") && this.state.productDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveProduct("saveAndNext");
                    }}>
                        <Translate contentKey="entity.action.add">SaveAndNext</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/product") && this.state.productDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveProduct("saveAndCopy");
                    }}>
                        <Translate contentKey="entity.action.add">SaveAndCopy</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/product") && this.state.productDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.productTemp;

                        if (GeneralUtils.isNullOrEmpty(obj1.id)) {
                            window.history.back();
                        } else {
                            this.setState({
                                product: Object.assign({}, obj1), productDetailsSaveButtonRender: false
                            });
                        }
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>

                }

                {GeneralUtils.checkPrivilege("delete_product/product/_id") && !this.state.productDetailsSaveButtonRender &&
                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.showDeleteConfirmDialog()
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>
                }


            </Fieldset>

        </div>)
    };

    showDeleteConfirmDialog = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            return toast.warn("Select a product!");
        } else {
            this.setState({
                displayDeleteConfirmDialog: true,
                noticeResponse: "The product will be deleted. Do you want to continue?"
            });
        }
    }

    returnListPage = () => {
        window.location.href = "#productList";
    }

    deleteProduct = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            this.productService.delete(this.state.product.id)
                .then((response) => {
                    this.returnListPage();
                });
        }
    }

    updateStock = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            this.productService.updateStock(this.state.product)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            productDetailsSaveButtonRender: false,
                            stockDetailsSaveButtonRender: false,
                            product: response,
                            productTemp: Object.assign({}, response)

                        });
                    }
                });
            this.calculateFields();
        }

    }
    saveProduct = (type) => {
        if (GeneralUtils.isNullOrEmpty(this.state.product.name) ||
            GeneralUtils.isNullOrEmpty(this.state.product.barcode) ||
            GeneralUtils.isNullOrEmpty(this.state.product.quantity) ||
            GeneralUtils.isNullOrEmpty(this.state.product.departmentId) ||
            GeneralUtils.isNullOrEmpty(this.state.product.unit)
        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
                this.productService.update(this.state.product)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            if (type == "save") {
                                this.setState({
                                    productDetailsSaveButtonRender: false,
                                    stockDetailsSaveButtonRender: false,
                                    product: response,
                                    productTemp: Object.assign({}, response)

                                });
                                this.returnListPage();
                            } else if (type == "saveAndNext") {
                                let obj1 = Object.assign({}, defaultProduct);
                                this.setState({
                                    product: obj1,
                                    productTemp: Object.assign({}, obj1),
                                    stockDetailsSaveButtonRender: true,
                                    productDetailsSaveButtonRender: true,

                                });
                            }
                            if (type == "saveAndCopy") {
                                let obj1 = Object.assign({}, response);
                                obj1.id = null;
                                obj1.barcode = null;
                                this.setState({
                                    product: obj1,
                                    productTemp: Object.assign({}, obj1),
                                    stockDetailsSaveButtonRender: true,
                                    productDetailsSaveButtonRender: true,

                                });
                            }

                            this.uploadDocument(response.id);
                        }
                    });
                this.calculateFields();
            } else {
                this.productService.create(this.state.product)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            if (type == "save") {
                                this.setState({
                                    productDetailsSaveButtonRender: false,
                                    stockDetailsSaveButtonRender: false,
                                    product: response,
                                    productTemp: Object.assign({}, response)
                                });
                                this.returnListPage();
                            } else if (type == "saveAndNext") {
                                let obj1 = Object.assign({}, defaultProduct);
                                this.setState({
                                    product: obj1, productTemp: Object.assign({}, obj1),
                                    stockDetailsSaveButtonRender: true, productDetailsSaveButtonRender: true,

                                });
                            }
                            if (type == "saveAndCopy") {
                                let obj1 = Object.assign({}, response);
                                obj1.id = null;
                                obj1.barcode = null;
                                this.setState({
                                    product: obj1, productTemp: Object.assign({}, obj1),
                                    stockDetailsSaveButtonRender: true, productDetailsSaveButtonRender: true,

                                });
                            }

                            this.uploadDocument(response.id);
                        }
                    });
                this.calculateFields();
            }
        }
    }


    removeDocument(type) {
        if (!GeneralUtils.isNullOrEmpty(this.state.product.id)) {
            this.productService.removeDocument(this.state.product.id, type).then(response => {
                this.setState({
                    productDetailsSaveButtonRender: false,
                    product: response,
                })
            });
        }

        if (type == WEB_MENU_SMALL_IMAGE) {
            this.refUploadInputImage.clear();
        } else {
            this.refUploadInputImageTill.clear();
        }

    }

    uploadDocument(id) {
        let image = this.refUploadInputImage.files;
        let imageTill = this.refUploadInputImageTill.files;
        if (!(GeneralUtils.isNullOrEmpty(image) || GeneralUtils.isNullOrEmpty(image.length))) {
            let formData = new FormData();
            if (!GeneralUtils.isNullOrEmpty(image) && !GeneralUtils.isNullOrEmpty(image.length)) {
                formData.append("file", image[0]);
                this.productService.uploadDocument(id, formData, WEB_MENU_SMALL_IMAGE).then(response => {
                    this.setState({
                        productDetailsSaveButtonRender: false,
                        stockDetailsSaveButtonRender: false,
                    })
                });
            }
        }

        if (!(GeneralUtils.isNullOrEmpty(imageTill) || GeneralUtils.isNullOrEmpty(imageTill.length))) {
            let formData = new FormData();
            if (!GeneralUtils.isNullOrEmpty(imageTill) && !GeneralUtils.isNullOrEmpty(imageTill.length)) {
                formData.append("file", imageTill[0]);
                this.productService.uploadDocument(id, formData, TILL_IMAGE).then(response => {
                    this.setState({
                        productDetailsSaveButtonRender: false,
                        stockDetailsSaveButtonRender: false,
                    })
                });
            }
        }
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let product = this.state.product;
        product[e.target.name] = value;

        if (e.target.name == "barcode" && value.length < 12) {
            product['barcodeType'] = 'NORMAL';
            product['barcodeDigit'] = null;
            product['codeLength'] = null;
        }
        if (e.target.name == "departmentId") {
            var departmentProfitMargin = this.state.departmentList.filter(x => x.id == value).map(x => x.profitMargin)[0];
            if (GeneralUtils.isNullOrEmpty(product['profitMargin'])) {
                product['profitMargin'] = departmentProfitMargin;
                product['departmentProfitMargin'] = departmentProfitMargin;
            } else {
                product['departmentProfitMargin'] = departmentProfitMargin;
            }

            var departmentTaxId = this.state.departmentList.filter(x => x.id == value).map(x => x.tax)[0];
            product['tax'] = departmentTaxId;
            product['departmentTaxId'] = departmentTaxId;

        }
        if (e.target.name == "stockOperation") {
            if (value == "ADD_STOCK") {
                product['stockOperationReason'] = "DELIVERY";
            } else if (value == "DROP_STOCK") {
                product['stockOperationReason'] = "EXPIRED";
            } else {
                product['stockOperationReason'] = "";
            }
        }

        this.setState({product: product});

        this.calculateFields();
    }


    calculateWebPrice = () => {
        let product = this.state.product;
        if (!GeneralUtils.isNullOrEmpty(product.price)) {
            this.productService.calculateWebPrice(product).then(response => {
                product["webPrice"] = response;
                this.setState({product: product});
            });
        }


    }

    calculateFields = () => {
        try {
            var HUNDRED = new Decimal(100);
            let product = this.state.product;

            product['retailPrice'] = null;
            product['actualMargin'] = null;
            product['unitCost'] = null;
            var boxQty = new Decimal(product.boxQuantity ? product.boxQuantity : 1);
            var vatRate = (new Decimal(product.tax ? this.state.taxList.filter(x => x.id == product.tax).map(x => x.rate)[0] : (product.departmentId ? this.state.departmentList.filter(x => x.id == product.departmentId).map(x => x.taxRate)[0] : 0.0))).plus(HUNDRED).div(HUNDRED);
            // cost is assumed to be VAT exclude
            var unitCost = (new Decimal(product.costPrice ? product.costPrice : 0.0)).div(boxQty);
            var costPrice = unitCost.times(vatRate);
            var itemPrice = new Decimal(product.price ? product.price : 0.0);
            var profitMarginRate = (new Decimal(product.profitMargin ? product.profitMargin : 0.0)).plus(HUNDRED).div(HUNDRED);

            product['unitCost'] = parseFloat(unitCost);

            if (parseFloat(costPrice)) {
                product['actualMargin'] = parseFloat((itemPrice.div(costPrice).times(HUNDRED).minus(HUNDRED)).toFixed(2));
            }

            product['retailPrice'] = parseFloat((profitMarginRate.times(costPrice)).toFixed(2));

            this.setState({product: product});
        } catch (e) {

        }

    }

    searchProduct = (event) => {
        let value = event.query;
        let product = this.state.product;
        product["name"] = value;
        this.setState({
            product: product,
        });

        setTimeout(() => {
            this.productService.searchProductsByName(value).then(response => {
                this.setState({filteredProducts: response});

            });
        }, 250);

    }

}

