import {toast} from 'react-toastify';
import {PAGE_MODE, SERVER_URL} from "./Constants";
import printJS from "print-js";
import Cookies from "universal-cookie";
import TillService from "../service/TillService";


export default class GeneralUtils {
    static isNullOrEmpty(value) {
        return (!value || value === undefined || value === "" || value === "null" || value.length === 0 || JSON.stringify(value) === JSON.stringify({}));
    }

    static __isValueEmpty(value) {
        switch (typeof value) {
            case "undefined":
                return true;
            case "object":
                if (value === null) return true;
                return Object.keys.length === 0;
            case "string":
                return (value === undefined || value === null || value.trim() === "");
            default:
                break;
        }
    }

    static isMobile() {
        let isMobile = false;
        if (navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/iPhone/i)) {
            isMobile = true;
        }
        //console.log(window.innerWidth)
        return isMobile;
    }


    static getDialogStyleForLoginOrContinueAsGuestDialog() {
        return this.isMobile() ? {width: '100%', height: '30%', margin: "5px", textAlign: "center"} : {
            width: '40vw',
            height: '30vh',
            textAlign: "center"
        }
    }

    static getDialogStyleForDialog() {
        return this.isMobile() ? {width: '100%', height: '100%', margin: "10px"} : {
            width: '30vw',
            height: '35vw',
            marginLeft: "auto",
            marginRight: "auto"
        }
    }

    static getDialogStyleForProduct() {
        return this.isMobile() ? {width: '100%', height: '40%', margin: "10px"} : {
            width: '30%',
            height: '35%',
            marginLeft: "auto",
            marginRight: "auto"
        }
    }

    static isSetMenu(item) {
        let isSetMenu = false;
        if (!this.isNullOrEmpty(item.grpOption1) || !this.isNullOrEmpty(item.grpOption2) || !this.isNullOrEmpty(item.grpOption3) || !this.isNullOrEmpty(item.grpOption4) || !this.isNullOrEmpty(item.grpContent) || !this.isNullOrEmpty(item.grpContentX) || !this.isNullOrEmpty(item.grpMain)) {
            isSetMenu = true;
        }
        return isSetMenu
    }

    static getDialogStyleMerchantForDialog() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("scope"))) { // till ekranı icin
            return {width: '90%', height: '90%'};
        } else {
            return this.isMobile() ? {width: '100%', height: '100%', margin: "10px"} : {width: '70%', height: '85%'}
        }

    }

    // Returns if a value is an object
    static isObject(value) {
        return value && typeof value === 'object' && value.constructor === Object;
    }

    static convertToDownloadableFile = (response) => {
        var sampleArr = this.base64ToArrayBuffer(response.file);
        this.openDocument(response.fileName, sampleArr, response.fileType);
    }

    static printFile = (response) => {
        var sampleArr = this.base64ToArrayBuffer(response.file);
        this.printDocument(response.fileName, sampleArr, response.fileType);
    }

    static getBlob = (response) => {
        var sampleArr = this.base64ToArrayBuffer(response.file);
        var type = response.fileType;
        var blob = new Blob([sampleArr], {type: type});
        var url = URL.createObjectURL(blob);
        return url;
    }
    static downloadFile = (response) => {
        var sampleArr = this.base64ToArrayBuffer(response.file);
        this.saveByteArray(response, sampleArr);
    }

    static saveByteArray = (response, sampleArr) => {
        var blob = new Blob([sampleArr], {type: response.fileType});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = response.fileName;
        link.click();
    };

    static base64ToArrayBuffer = (base64) => {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    static previewDocument = (element) => {
        var reader = new FileReader();
        let file = element.files[0];
        reader.onload = function () {
            var arrayBuffer = this.result;
            var type = file.type;
            var blob = new Blob([arrayBuffer], {type: type});
            var url = URL.createObjectURL(blob);
            window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=600,width=600,height=600");
        }
        reader.readAsArrayBuffer(file);
    }


    static openDocument = (fileName, sampleArr, fileType) => {
        var reader = new FileReader();
        var type = fileType;
        var blob = new Blob([sampleArr], {type: type});
        var url = URL.createObjectURL(blob);
        window.open(url, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=600,width=600,height=600");
    }

    static printDocument = (fileName, sampleArr, fileType) => {
        var reader = new FileReader();
        var type = fileType;
        var blob = new Blob([sampleArr], {type: type});
        var url = URL.createObjectURL(blob);
        printJS(url);
    }

    static convertForImageSource = (file, fileType, fileName) => {
        var sampleArr = this.base64ToArrayBuffer(file);
        return this.getUrl(fileName, sampleArr, fileType);
    }

    static getUrl = (fileName, sampleArr, fileType) => {
        var type = fileType;
        var blob = new Blob([sampleArr], {type: type});
        var url = URL.createObjectURL(blob);
        return url;
    }
    static downloadDocument = (element) => {
        let file = element.files[0];
        var blob = new Blob([file], {type: file.type});
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = file.name;
        link.click();
    }

    static checkPasswordFormat(str) {
        if (str.length > 50) {
            return true;
        }
        var re = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;
        return re.test(str);
    }

    static formatGBP(value) {
        return (value).toLocaleString('en-GB', {
            style: 'currency',
            currency: 'GBP',
        });
    }

    static clearCookieAndLocalStorage() {
        let merchantBranchId = localStorage.getItem("merchantBranchId");
        let tableOrderLicense = localStorage.getItem("TABLE_ORDER_LICENSE");
        let webOrderLicense = localStorage.getItem("WEB_ORDER_LICENSE");
        let webMenuLicense = localStorage.getItem("WEB_MENU_LICENSE");
        let orderType = localStorage.getItem("orderType");
        let clientId = localStorage.getItem("clientId");
        let token_url = localStorage.getItem("token_url");
        let orgHref = localStorage.getItem("orgHref");
        let appMode = localStorage.getItem("appMode");


        localStorage.clear();
        this.setJwtToCookie(null, null, null)
        localStorage.setItem("appMode", appMode);
        localStorage.setItem("merchantBranchId", merchantBranchId);
        localStorage.setItem("TABLE_ORDER_LICENSE", tableOrderLicense);
        localStorage.setItem("WEB_ORDER_LICENSE", webOrderLicense);
        localStorage.setItem("WEB_MENU_LICENSE", webMenuLicense);
        localStorage.setItem("orderType", orderType);
        localStorage.setItem("clientId", clientId);
        localStorage.setItem("token_url", token_url);
        localStorage.setItem("orgHref", orgHref);

    }

    static getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    static async getJwtFromCookieOrTillService() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("userType")) && localStorage.getItem("userType") == "tillUser") {
            this.tillService = new TillService();
            const result = (await this.tillService.getTokens());
            return result.accessToken;

        } else {
            return  localStorage.getItem('json_token');
        }

    }

    static getRefreshTokenFromCookie() {
        return  localStorage.getItem('refresh_token');

    }

    static setJwtToCookie(token, refreshToken, expireDate) {
        localStorage.setItem("json_token",token);
        localStorage.setItem("refresh_token",refreshToken);
        localStorage.setItem("expireDate",expireDate);

    }

    static responseToJson(response) {
        return response.json();
    }

    static validateEmail(email) {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    static onValidationFailErrorFile = (e) => {
        toast.error(e.detail);
    }

    static removeDuplicates = (arr) => {
        const uniqueIds = [];
        const unique = arr.filter(element => {
            const isDuplicate = uniqueIds.includes(element.id);
            if (!isDuplicate) {
                uniqueIds.push(element.id);
                return true;
            }
            return false;
        });
        return unique;
    }

    static notification(response) {
        this.notificationMessage(response, false, true)
    }

    static notificationPayNoAttention(response) {
        this.notificationMessage(response, false, false)
    }

    static notification(response, hiddenSuccess) {
        this.notificationMessage(response, hiddenSuccess, true)
    }

    static notificationMessage(response, hiddenSuccess, payNoAttention) {
        if (payNoAttention == true && (response == undefined || response.status == undefined)) {

            if (response == "TypeError: Failed to fetch") {

            } else {
                if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("userType")) && (localStorage.getItem("userType") == "merchant" || localStorage.getItem("userType") == "admin")) {
                    GeneralUtils.clearCookieAndLocalStorage()
                    window.location = "";
                } else {
                    GeneralUtils.clearCookieAndLocalStorage()
                }
            }
            //
        } else {
            switch (response.status) {
                case 200:
                    response.json().then(function (notification) {
                        if (notification.type) {
                            GeneralUtils.showNotification(notification);
                        } else {
                            if (!hiddenSuccess) {
                                toast.success("The operation was performed successfully.");
                            }
                        }
                    });
                    break;
                case 400 :
                    response.json().then(function (notification) {
                        if (notification.type) {
                            GeneralUtils.showNotification(notification);
                        } else {
                            toast.warn("Empty Values.");
                        }
                    });
                    break;
                case 401 :
                    if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("userType")) && (localStorage.getItem("userType") == "merchant" || localStorage.getItem("userType") == "admin")) {
                        GeneralUtils.clearCookieAndLocalStorage()
                        window.location = "";
                    } else {
                        GeneralUtils.clearCookieAndLocalStorage()
                    }
                    break;

                case 403 :
                    response.json().then(function (notification) {
                        toast.warn("Not Authorized");
                    });
                    break;

                case 404 :
                    response.json().then(function (notification) {
                        if (notification.type) {
                            GeneralUtils.showNotification(notification);
                        } else {
                            toast.warn("Error");
                        }
                    });
                    break;

                case 409 :
                    response.json().then(function (notification) {
                        if (notification.type) {
                            GeneralUtils.showNotification(notification);
                        } else {
                            toast.warn("Duplicate Value");
                        }
                    });
                    break;


                default:
                    console.log(response);
                    toast.error("An unexpected error has occurred. Error code: " + response.status);
                    //GeneralUtils.clearCookieAndLocalStorage()
                    break;
            }
        }
    }

    static showNotification(notification) {

        switch (notification.type) {
            case "SUCCESS":
                toast.success(notification.details);
                break;
            case "INFO" :
                toast.info(notification.details);
                break;
            case "WARNING" :
                toast.warn(notification.details);
                break;
            case "ERROR":
                toast.error(notification.details);
                break;
            default:
                toast.error("An unexpected error has occurred. " + notification.details);
                break;
        }
    }

    static getUserNameAndFamilyNames() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.firstName + ' ' + (GeneralUtils.isNullOrEmpty(hsmUserLocalStorage.lastName) ? "" : hsmUserLocalStorage.lastName) : '';
    }

    static isAuthenticated() {
        var isAuthenticated = JSON.parse(localStorage.getItem("isAuthenticated"));
        return (!this.__isValueEmpty(isAuthenticated)) ? isAuthenticated : false;
    }

    static hasWebMenuLicense() {
        var result = JSON.parse(localStorage.getItem("WEB_MENU_LICENSE"));
        return (!this.__isValueEmpty(result)) ? result : false;
    }

    static hasWebOrderLicense() {
        var result = JSON.parse(localStorage.getItem("WEB_ORDER_LICENSE"));
        return (!this.__isValueEmpty(result)) ? result : false;
    }

    static hasTableOrderLicense() {
        var result = JSON.parse(localStorage.getItem("TABLE_ORDER_LICENSE"));
        return (!this.__isValueEmpty(result)) ? result : false;
    }


    static getFirstName() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.firstName : '';
    }

    static getUserName() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.userName : ' ';
    }

    static getUserRoleList() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.roles : null;
    }

    static getUserGroup() {
        var hsmUserLocalStorage = JSON.parse(localStorage.getItem("hsmUser"));
        return (!this.__isValueEmpty(hsmUserLocalStorage)) ? hsmUserLocalStorage.group : null;
    }

    static isClerk() {
        return this.hasRole("CLERK");
    }

    static isKitchen() {
        return this.hasRole("KITCHEN");
    }

    static isDriver() {
        return this.hasRole("DRIVER");
    }

    static hasRole(roleName) {
        var hasRole = false;
        if (localStorage.getItem("scope") == null) {
            var list = this.getUserRoleList();
            if(list != null){
                for (var i = 0; i < list.length; i++) {
                    if (list[i].name === roleName) {
                        hasRole = true;
                    }
                }
            }
            var userGroup = this.getUserGroup();
            if(userGroup == "MERCHANT_BRANCH_ADMIN"){
                hasRole = true;
            }

        } else {
            if (localStorage.getItem("scope") && !GeneralUtils.isNullOrEmpty(localStorage.getItem("scope")) && localStorage.getItem("scope").indexOf(roleName) >= 0) {
                hasRole = true;
            }
        }
        return hasRole;
    }

    static isWaiter() {
        return this.hasRole("WAITER");
    }

    static getPrivilegeListFromRoleList() {
        var hsmRoleList = this.getUserRoleList();
        let merchantRolePrivilegeList = []
        hsmRoleList.forEach((element) => {
            element.merchantRolePrivilegeList.forEach((privilege) => {
                merchantRolePrivilegeList.push(privilege);
            });
        });
        return (!this.__isValueEmpty(merchantRolePrivilegeList)) ? merchantRolePrivilegeList : null;
    }

    static getPrivilegeList() {
        var hsmPrivilegeList = JSON.parse(localStorage.getItem("hsmPrivilegeList"));
        return this.isNullOrEmpty(hsmPrivilegeList) ? null : hsmPrivilegeList;
    }

    static checkPrivilege(object) {
        var hsmPrivilegeList = this.getPrivilegeList();
        if (hsmPrivilegeList != null) {
            for (var element of hsmPrivilegeList) {
                if (element == object ) {
                    return true;
                }
            }
        }
        return false;
    }


    static getDialogHeader(objectName, pageMode) {
        if (pageMode === PAGE_MODE.VIEW) {
            return objectName + ' View';
        } else if (pageMode === PAGE_MODE.UPDATE) {
            return objectName + '  Update';
        } else if (pageMode === PAGE_MODE.CREATE) {
            return 'New ' + objectName;
        }
    }

    static randomID() {
        return Math.random().toString(36).substr(2, 16);
    }

    static getReplaceValue(value) {
        return value.toString().replace("(", "").replace(")", "")
            .replace(" ", "").replace("-", "");
    }

    static returnMainPage() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("orgHref"))) {
            let url = localStorage.getItem("orgHref");
            url = url.substr(0, url.indexOf("?"));
            window.location.href = url;
        }
    }

    static getUiUrl() {
        if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("orgHref"))) {
            let url = localStorage.getItem("orgHref");
            if (url.indexOf("?") > 0) {
                url = url.substr(0, url.indexOf("?"));
            } else {
                if (url.indexOf("/#/") > 0) {

                } else {
                    url = url.substr(0, url.length);
                }

            }
            return url.toString();
        } else {
            return window.location.href;
        }
    }

    static addQueryParam = (key, value) => {
        const url = new URL(window.location.href);
        url.searchParams.set(key, value);
        window.history.pushState({}, '', url.toString());
    };

    static removeQueryParam = (key) => {
        const url = new URL(window.location.href);
        url.searchParams.delete(key);
        window.history.pushState({}, '', url.toString());
    };

    static getQueryParam = (key) => {
        const url = new URL(window.location.href);
        return url.searchParams.get(key) || '';
    };

    static removeQueryString(url) {
        const urlObj = new URL(url);
        urlObj.search = '';
        return urlObj.toString();
    }

    static isServedInWebMarketUrl() {
        let url = window.location.href;
        if (url.indexOf("dispatchio") > 0 || url.indexOf("posmarket") > 0) {
            return true;
        } else {
            return false;
        }
    }

    static isTestEnvironment() {
        return SERVER_URL == "https://admin.posmarket.site/posmarket/" ? true : false;
    }

    static isProductionEnvironment() {
        return SERVER_URL == "https://admin.dispatchio.store/posmarket/" ? true : false;
    }

    static getDomainName() {
        let url = window.location.href;

        if (url.includes("fbclid")) {
            url = this.removeQueryString(url);
            window.location.href = url;
        }
        var domainName = url.replace("/paymentMethods", "").replace("/approveOrder", "").replace('http://', '').replace("https://", "").replace("#/", "").replace("/#/", "").replace("/", ".").replace("#", "").replace("www.", "").replace("order.", "").split(".");
        if (domainName[0] == "dispatchio" || domainName[0] == "posmarket") {
            var result = domainName[domainName.length - 1].split(".");
            if (window.location.href.includes("accessToken")) {
                return null;
            }
            else{
                return result[result.length - 1] == "marketplace" ? "" : result[result.length - 1].replace("/", "");
            }

        } else {
            return domainName[0].replace("/", "");
        }

    }

    static sliceString(data, length) {
        if (data.length > length) {
            return data.slice(0, length) + " ..."
        } else
            return data;

    }

    static removeSlashes = (value) => {
        if(value != undefined){
            return value.replace('/','').replace('\\\\','').replace('"','');
        }

    }


}
