import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'reactstrap';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import PasswordStrengthBar from '../../shared/component/password/password-strength-bar';
import {toast} from "react-toastify";
import MemberUserSecurityService from "../../shared/service/MemberUserSecurityService";

export default class PasswordResetFinishMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            activationKey: this.props.activationKey,
            passwordChangeKey: this.props.passwordChangeKey
        };
        this.memberUserSecurityService = new MemberUserSecurityService();
    }

    render() {
        return (
            <div>
                <AvForm>
                    <AvField
                        name="newPassword"
                        label="New Password"
                        placeholder="New Password"
                        type="password"
                        validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            },
                            minLength: {
                                value: 8,
                                errorMessage: 'This field can contain at least 8 characters'
                            },
                            maxLength: {
                                value: 255,
                                errorMessage: 'This field can contain up to 255 characters.'
                            }
                        }}
                        onChange={this.__handleChange}
                    />
                    <PasswordStrengthBar password={this.state.newPassword}/>
                    <AvField
                        name="confirmPassword"
                        label="Password Again"
                        placeholder="Password Again"
                        type="password"
                        onChange={this.__handleChange}
                        validate={{
                            required: {
                                value: true,
                                errorMessage: 'This field is required.'
                            },
                            minLength: {
                                value: 8,
                                errorMessage: 'This field can contain at least 8 characters'
                            },
                            maxLength: {
                                value: 255,
                                errorMessage: 'This field can contain up to 255 characters.'
                            },
                            match: {
                                value: 'newPassword',
                                errorMessage: 'Passwords did not match!'
                            }
                        }}
                    />
                    <Button color="success" onClick={this.handleValidSubmit}>
                        <Translate contentKey="reset.finish.form.button">Save</Translate>
                    </Button>
                </AvForm></div>
        );
    }

    __handleChange = (e) => {
        let state = {};
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        state[e.target.name] = value;
        this.setState(state);
    }

    handleValidSubmit = () => {
        if (!GeneralUtils.checkPasswordFormat(this.state.newPassword)) {
            return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
        }
        if (this.state.newPassword != this.state.confirmPassword) {
            return toast.warn("Passwords did not match!");
        }

        if (GeneralUtils.getParameterByName('passwordChangeKey') !== null) {
            let data = {
                key: GeneralUtils.getParameterByName('passwordChangeKey'),
                newPassword: (this.state.newPassword).toString()
            };
            this.memberUserSecurityService.resetPasswordFinish(data);
            this.props.closeDisplayDialog();
            window.location.hash = "/";
        };
        }



}
PasswordResetFinishMember.propTypes = {
    activationKey: PropTypes.string,
    passwordChangeKey: PropTypes.string,
};