import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import {Fieldset} from "primereact/fieldset";
import DataTable from "../../shared/component/dataTable/DataTable";
import MixAndMatchColumn from "../../shared/component/dataTable/columns/MixAndMatchColumn.json";
import MixAndMatchService from "../../shared/service/MixAndMatchService";


export default class MixAndMatchList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filteredProducts: []
        }
        this.mixAndMatchService = new MixAndMatchService();
    }

    componentDidMount() {

        this.refresh();
    }

    refresh() {
       this.refDataTable.refresh();
    }

    render() {
        return (<div>
            <AvForm>
                {this.mixAndMatches()}
            </AvForm>
        </div>);
    }


    mixAndMatches() {

        return (<div class="p-col-12">

            <Fieldset legend="Mix And Match List">
                {GeneralUtils.checkPrivilege("post_product/mixandmatch") &&
                    <Button color="outline-primary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => {
                        window.location.href = "#mixAndMatchSave?"
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }
                {GeneralUtils.checkPrivilege("put_product/mixandmatch/all") &&
                    <DataTable ref={(elem) => this.refDataTable = elem}
                               fields={MixAndMatchColumn.fields}
                               objectName={MixAndMatchColumn.name}
                               rows={5}
                               dataURL={"product/mixandmatch/all"}
                               selectionMode="single" onDataSelect={this.onDataSelect}
                               loading={this.state.loading}/>
                }
            </Fieldset>


        </div>)
    };


    onDataSelect = (data) => {
        window.location.href = "#mixAndMatchSave?"+data.id;
    }


}

