import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class CurrentUserService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/user';
    }

    async getCurrentMerchantUser() {
        return fetch(SERVER_URL + this.baseURL + "/merchant/currentUser" , {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            //GeneralUtils.notification(error);
        });
    }

    async getCurrentAdminOrMemberUser() {
        return fetch(SERVER_URL + this.baseURL + "/currentUser" , {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => response.json()).catch(error => {
            //GeneralUtils.notification(error);
        });
    }


}