import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class WebMenuService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/webmenu';
    }

    async findParents() {
        return fetch(SERVER_URL + this.baseURL + "/all/parent", {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findAllSubMenus() {
        return fetch(SERVER_URL + this.baseURL + "/all/hassubmenu", {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findSubMenusByParentId(parentId) {
        return fetch(SERVER_URL + this.baseURL + "/all/menu/sub/" + parentId, {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async addItemToWebMenu(parentId, productId,grpMainId) {
        return fetch(SERVER_URL + this.baseURL + "/item/add/"+ parentId + "/" + productId + "/" + grpMainId  , {
            crossDomain: true,
            method: 'put',
            headers: {
                'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateWebMenuDisplay(data) {
        return fetch(SERVER_URL + this.baseURL + "/display", {
            crossDomain: true,
            method: 'put',
            headers: {
                'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async transfer() {
        return fetch(SERVER_URL + this.baseURL + "/transfer", {
            crossDomain: true,
            method: 'put',
            headers: {
                'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            }
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}