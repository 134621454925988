import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {STATUS, YESNO} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {PhoneField} from "../../shared/component/form/PhoneField";
import AdminUserColumn from "../../shared/component/dataTable/columns/AdminUserColumn.json";
import AdminUserService from "../../shared/service/AdminUserService";
import DataTable from "../../shared/component/dataTable/DataTable";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import CurrentUserService from "../../shared/service/CurrentUserService";

const defaultAdminUser = {
    id: '',
    userName: '',
    title:'MR',
    status: 'ACTIVE',
    group:"OWNER",
    mobilePhone: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    branches: [],
    locked: 'N',
    password: '',
    wrongEntryNumbers: 0,
    superUser: false,
    activationDate: null,
    sendNotification: true,
    passwordChangeDate: null
};
export default class AdminUserSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminUserList: [],
            adminUser: defaultAdminUser,
            adminUserTemp: defaultAdminUser,
            adminUserDetailsSaveButtonRender: true,
            adminUserStatus: 'ACTIVE'
        }
        this.adminUserService = new AdminUserService();
        this.currentUserService = new CurrentUserService()
    }

    componentDidMount() {
        let obj1 = Object.assign({}, defaultAdminUser);
        let obj2 = Object.assign({}, defaultAdminUser);
        this.setState({
            adminUser: obj1, adminUserTemp: obj2, adminUserDetailsSaveButtonRender: false
        });
        this.refresh();

    }

    refresh() {
        this.adminUserService.findAllByGroupName("OWNER").then(response => this.setState({
            adminUserList: response
        }));
    }

    render() {
        return (<div>
            <AvForm autocomplete="off">
                {this.adminUserDetails()}
            </AvForm>
        </div>);
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.adminUser.userId)) {
            this.adminUserService.updateStatus(this.state.adminUser.userId, this.state.adminUserStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            adminUser: response,
                            adminUserTemp: response,
                            adminUserDetailsSaveButtonRender: false
                        });
                        this.refresh();
                    }
                });
        } else {
            let adminUser = this.state.adminUser;
            adminUser["status"] = this.state.adminUser.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({adminUser: adminUser, adminUserTemp: adminUser});
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let adminUserStatus = 'PASSIVE';
        if (value) {
            adminUserStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            adminUserStatus: adminUserStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }

    adminUserDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Admin User Details">
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <Fieldset legend="Status">
                                    <Col md="3">
                                    </Col>
                                    <Col md="9">
                                        <Row>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="status"
                                                disabled={true}
                                                value={this.state.adminUser.status}
                                                onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>

                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                                      onCancelClick={() => this.setState({displayShowDeleteConfirmDialog: false})}
                                                      show={this.state.displayShowDeleteConfirmDialog}
                                                      message={this.state.noticeResponse}/>
                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                          show={this.state.displayShowStatusConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <InputSwitch
                                                checked={this.state.adminUser.status == "ACTIVE" ? true : false}
                                                tooltip={"Change Status"}
                                                disabled={!this.state.adminUserDetailsSaveButtonRender}
                                                onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                        </Row>

                                    </Col>

                                </Fieldset>

                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="adminUser.tradingName">Username</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        className="form-control"
                                        name="userName"
                                        id="adminUserName1"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender || !GeneralUtils.isNullOrEmpty(this.state.adminUser.userId)}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.adminUser.userName}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="adminUser.lastName">Password</Translate>
                                    </Label>

                                    <AvField
                                        value={this.state.adminUser.password}
                                        style={{webkitTextSecurity: "disc", textSecurity: "disc"}}
                                        name="password"
                                        type={"text"}
                                        className="form-control"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender}
                                        onChange={this.__handleChange}

                                    />
                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="adminUser.emailAddress">Email</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="email"
                                        id="email1"
                                        className="form-control"
                                        name="email"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender}
                                        validate={{
                                            minLength: {
                                                "value": 5, "errorMessage": "Not Valid Email"
                                            }, maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.adminUser.email}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>


                        </Row>
                        <Row>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="adminUser.firstName">First Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.adminUser.firstName}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="adminUser.lastName">Last Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.adminUser.lastName}
                                        onChange={this.__handleChange}/>
                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Mobile
                                            Phone</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <PhoneField kind={'mobile'}
                                                disabled={!this.state.adminUserDetailsSaveButtonRender}
                                                name="mobilePhone" errorMessage="Not Valid Phone Number"
                                                value={this.state.adminUser.mobilePhone}
                                                onChange={this.__handleChange}
                                    />
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">Send Notification?</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="sendNotification"
                                        disabled={!this.state.adminUserDetailsSaveButtonRender}
                                        value={this.state.adminUser.sendNotification}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {YESNO.map(element => (<option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {this.state.adminUserDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.adminUser.userId) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({adminUserDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {this.state.adminUserDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                        this.saveAdminUser();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }
                {this.state.adminUserDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                        let obj1 = this.state.adminUserTemp;
                        this.setState({
                            adminUser: Object.assign({}, obj1), adminUserDetailsSaveButtonRender: false
                        });


                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }

                 { this.state.adminUserDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.adminUser.userId) &&

                                        <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                                            this.showChangeDeleteConfirmDialog(true)
                                        }}>
                                            <Translate contentKey="entity.action.add">Delete</Translate>
                                        </Button>}

            </Fieldset>

            <Fieldset legend="Admin Users">
                <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                    this.addNew();
                }}>
                    <Translate contentKey="entity.action.add">Add</Translate>
                </Button>
                <DataTableAll ref={(elem) => this.refDataTable = elem} fields={AdminUserColumn.fields}
                           objectName={AdminUserColumn.name}
                           data={this.state.adminUserList}

                           selectionMode="single" onDataSelect={this.onDataSelect}
                           loading={this.state.loading}/>
            </Fieldset>

        </div>)
    };

    showChangeDeleteConfirmDialog = (value) => {
        this.setState({
            displayShowDeleteConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

     delete = () => {
            if (!GeneralUtils.isNullOrEmpty(this.state.adminUser.userId)) {
                this.adminUserService.delete(this.state.adminUser.userId)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                           this.refresh();
                            let obj1 = Object.assign({}, defaultAdminUser);
                               let obj2 = Object.assign({}, defaultAdminUser);

                               this.setState({
                                   adminUser: obj1, adminUserTemp: obj2, adminUserDetailsSaveButtonRender: false
                               });
                        }
                    });
            }

            return undefined;
        }

    onDataSelect = (data) => {
        this.adminUserService.findById(data.userId).then(response => {
            this.setState({
                adminUser: Object.assign({}, response),
                adminUserTemp: Object.assign({}, response),
                adminUserDetailsSaveButtonRender: false
            });
        });
    }
    saveAdminUser = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.adminUser.userName) || GeneralUtils.isNullOrEmpty(this.state.adminUser.mobilePhone) || GeneralUtils.isNullOrEmpty(this.state.adminUser.firstName) || GeneralUtils.isNullOrEmpty(this.state.adminUser.lastName) || GeneralUtils.isNullOrEmpty(this.state.adminUser.email) ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.adminUser.userId)) {
                this.state.adminUser["title"] = "MR";
                this.adminUserService.updateUser(this.state.adminUser.userId,this.state.adminUser)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                adminUserDetailsSaveButtonRender: false,
                                adminUser: Object.assign({}, response),
                                adminUserTemp: Object.assign({}, response)

                            });
                            this.refresh();
                        }
                    });
            } else {
                if (!GeneralUtils.isNullOrEmpty(this.state.adminUser.password) && !GeneralUtils.checkPasswordFormat(this.state.adminUser.password)) {
                    return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
                } else {
                    this.adminUserService.create(this.state.adminUser)
                        .then((response) => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.setState({
                                    adminUserDetailsSaveButtonRender: false,
                                    adminUser: Object.assign({}, response),
                                    adminUserTemp: Object.assign({}, response)
                                });
                                this.refresh();
                            }
                        });
                }

            }
        }

    }

    addNew() {
        let obj1 = Object.assign({}, defaultAdminUser);
        let obj2 = Object.assign({}, defaultAdminUser);

        this.currentUserService.getCurrentAdminOrMemberUser().then(response => this.setState({
            adminUser: obj1, adminUserTemp: obj2, adminUserDetailsSaveButtonRender: true
        }));

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let adminUser = this.state.adminUser;
        adminUser[e.target.name] = value;
        this.setState({adminUser: adminUser});
    }

}

