import React from 'react';
import {Button, Col, Label, Row, Card} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {STATUS} from "../../shared/utils/Constants";
import {InputSwitch} from "primereact/inputswitch";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import NumberFormat from "react-number-format";
import RestTableService from "../../shared/service/RestTableService";
import PdfDocument from "../merchant/PdfDocument";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import Loading from "../../shared/component/others/Loading";

const defaultRestTable = {
    id: '', status: 'ACTIVE', tableNo: null, tableCapacity: null
};

const defaultRestTableParam = {
    size: null, rowSize: null
};
export default class FloorPlanningSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            restTable: defaultRestTable,
            restTableParam: defaultRestTableParam,
            restTableTemp: defaultRestTable,
            loading: false,
            barcodeSource: '',
            restTableDetailsSaveButtonRender: true, defaultStatus: 'ACTIVE', restTableList: []
        }
        this.restTableService = new RestTableService();
        this.merchantBranchService = new MerchantBranchService();
    }


    componentDidMount() {
        let obj1 = Object.assign({}, defaultRestTable);
        this.setState({
            restTable: obj1, restTableDetailsSaveButtonRender: false,
            restTableTemp: Object.assign({}, obj1)
        });

        this.refresh();

    }

    refresh() {
        this.restTableService.findAll().then(response => this.setState({
            restTableList: response
        }));

        this.restTableService.getRestTableParam().then(response => this.setState({
            restTableParam: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.restTableDetails()}
                {this.barcodeDetails()}
                {this.barcodeReport()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    barcodeDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Qr Code">
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.downloadBarcode();
                }}>
                    <Translate contentKey="entity.action.add">Download</Translate>
                </Button>

                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.printBarcode();
                }}>
                    <Translate contentKey="entity.action.add">Print</Translate>
                </Button>

            </Fieldset>
        </div>)
    };

    generateUrl() {
        var url = GeneralUtils.getUiUrl().replaceAll("merchant.", "");
        url = url.slice(0, url.lastIndexOf("/") + 1);
        if (url.indexOf("/#/") < 0) {
            url = url.slice(0, url.lastIndexOf("/"))
        }
        url = url.replace("/#//", "/");
        url = url + "/" + localStorage.getItem("clientId") + "?";
        return url;

    }

    printBarcode = () => {
        if (this.state.restTableList.length == 0) {
            return toast.warn("Empty Floor Plan");
        } else {
            let url = this.generateUrl();

            let data = {
                url: url,
                numberOfTables: this.state.restTableList.length
            };

            this.setState({loading: true});
            this.merchantBranchService.generateBarcode(data).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.printFile(response);
                    this.setState({loading: false});
                }
            });
        }

    }
    previewBarcode = () => {
        if (this.state.restTableList.length == 0) {
            return toast.warn("Empty Floor Plan");
        } else {
            let url = this.generateUrl()
            let data = {
                url: url,
                numberOfTables: this.state.restTableList.length
            };
            this.setState({loading: true});
            this.merchantBranchService.generateBarcode(data).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    this.setState({
                        barcodeSource: GeneralUtils.getBlob(response),
                        loading: false
                    });
                }
            });
        }

    }
    downloadBarcode = () => {

        if (this.state.restTableList.length == 0) {
            return toast.warn("Empty Floor Plan");
        } else {
            let url = this.generateUrl();
            let data = {
                url: url,
                numberOfTables: this.state.restTableList.length
            };
            this.setState({loading: true});
            this.merchantBranchService.generateBarcode(data).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.file)) {
                    GeneralUtils.downloadFile(response);
                }
                this.setState({loading: false});
            });
        }

    }

    barcodeReport() {
        if (!GeneralUtils.isNullOrEmpty(this.state.barcodeSource)) {
            return (
                <div>
                    <Fieldset legend="Barcode">
                        <div className="all-page-container">
                            <PdfDocument pdf={this.state.barcodeSource}
                            />
                        </div>
                    </Fieldset>
                </div>
            );
        } else {
            return ''
        }
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.restTable.id)) {
            this.restTableService.updateStatus(this.state.restTable.id, this.state.defaultStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            restTable: response,
                            restTableDetailsSaveButtonRender: false,
                            restTableTemp: Object.assign({}, response)
                        });
                        this.refresh();
                    }
                });
        } else {
            let restTable = this.state.restTable;
            restTable["status"] = this.state.restTable.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({restTable: restTable, restTableTemp: Object.assign({}, restTable)});
        }

        return undefined;
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }
    setChecked = (name, value) => {
        let restTable = this.state.restTable;
        restTable[name] = value;
        this.setState({restTable: restTable});
    }

    restTableDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Floor Plan Details">
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <Fieldset legend="Status">
                                    <Col md="3">
                                    </Col>
                                    <Col md="9">
                                        <Row>
                                            <AvField
                                                type="select"
                                                className="form-control"
                                                name="status"
                                                disabled={true}
                                                value={this.state.restTable.status}
                                                onChange={this.__handleChange}>
                                                {STATUS.map(element => (
                                                    <option value={element.value} key={element.value}>
                                                        {element.name}
                                                    </option>))}
                                            </AvField>


                                            <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                          onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                          show={this.state.displayShowStatusConfirmDialog}
                                                          message={this.state.noticeResponse}/>

                                            <InputSwitch
                                                checked={this.state.restTable.status == "ACTIVE" ? true : false}
                                                tooltip={"Change Status"}
                                                disabled={!this.state.restTableDetailsSaveButtonRender}
                                                onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>
                                        </Row>

                                    </Col>

                                </Fieldset>

                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="restTable.tradingName">Table No</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <NumberFormat
                                        type="number"
                                        className="form-control"
                                        name="tableNo"
                                        disabled={!this.state.restTableDetailsSaveButtonRender}
                                        value={this.state.restTable.tableNo}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="restTable.tradingName">Table Capacity</Translate>
                                    </Label>

                                    <NumberFormat
                                        type="number"
                                        className="form-control"
                                        name="tableCapacity"
                                        disabled={!this.state.restTableDetailsSaveButtonRender}
                                        value={this.state.restTable.tableCapacity}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {GeneralUtils.checkPrivilege("post_dispatchio/resttable") && this.state.restTableDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.restTable.id) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({restTableDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_dispatchio/resttable") && this.state.restTableDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveRestTable();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("post_dispatchio/resttable") && this.state.restTableDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.restTableTemp;
                        this.setState({
                            restTable: Object.assign({}, obj1), restTableDetailsSaveButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>
            {GeneralUtils.checkPrivilege("post_dispatchio/resttable") &&
                <Fieldset legend={"Resize Table"}>

                    <Row>

                        <Col md={3}>
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="restTable.tradingName">Row Size</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <NumberFormat
                                    type="number"
                                    className="form-control"
                                    name="rowSize"
                                    disabled={false}
                                    value={this.state.restTableParam.rowSize}
                                    onChange={this.__handleChangeParam}/>

                            </AvGroup>
                        </Col>

                        <Col md={3}>
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="restTable.tradingName">Size</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <NumberFormat
                                    type="number"
                                    className="form-control"
                                    name="size"
                                    disabled={false}
                                    value={this.state.restTableParam.size}
                                    onChange={this.__handleChangeParam}/>

                            </AvGroup>
                        </Col>


                    </Row>

                    <Row>


                        <Col md={3}>

                            <ModalConfirm header='Warning' ok='Ok' onOkClick={this.resize}
                                          onCancelClick={() => this.setState({displayConfirmDialog: false})}
                                          show={this.state.displayConfirmDialog}
                                          message={this.state.noticeResponse}/>
                            <Button color="outline-primary" onClick={() => {
                                this.showConfirmDialog();
                            }}>
                                <Translate contentKey="entity.action.delete">Resize</Translate>
                            </Button>


                        </Col>
                    </Row>
                </Fieldset>

            }

            <Fieldset legend="Floor Plans">
                {GeneralUtils.checkPrivilege("get_dispatchio/resttable") &&
                    <Row>
                        {this.itemTemplate()}
                    </Row>
                }
            </Fieldset>
        </div>)
    };

    menuItem(item) {
        let style = {
            width: '5rem',
            marginBottom: '1em',
            marginLeft: '1em',
            marginRight: '1em',
            borderLeft: "6px solid #00bcd4 !important;"
        };
        return (<a href={"#floorPlanning"}>
            <Card title={item.description} style={style} onClick={() => this.onDataSelect(item)}>
                <p className="m-1" align={"center"}
                   style={{lineHeight: '1', textDecoration: 'underline;'}}>{item.tableNo}</p>
            </Card>
        </a>)
    }

    itemTemplate() {
        let form = [];
        var i = 1;
        this.state.restTableList.forEach(itemSub => {
            form.push(
                this.menuItem(itemSub)
            );
            i++;

        });
        return form;
    }

    showConfirmDialog = () => {
        this.setState({
            displayConfirmDialog: true,
            noticeResponse: "Do you want to continue?"
        });
    }

    resize = () => {
        let obj1 = this.state.restTableParam;
        if (GeneralUtils.isNullOrEmpty(obj1.size)) {
            return toast.warn("Please fill the Size field!");
        } else if (GeneralUtils.isNullOrEmpty(obj1.rowSize)) {
            return toast.warn("Please fill the Row Size field!");
        } else {
            this.restTableService.resize(obj1.size, obj1.rowSize).then(response => this.setState({
                restTableList: response
            }));
        }

    }

    onDataSelect = (data) => {
        this.restTableService.findById(data.id).then(response => {
            this.setState({
                restTable: response,
                restTableDetailsSaveButtonRender: false,
                restTableTemp: Object.assign({}, response)
            });

        });

    }
    saveRestTable = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.restTable.tableNo)) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.restTable.id)) {
                this.restTableService.update(this.state.restTable)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                restTableDetailsSaveButtonRender: false, restTable: response,
                                restTableTemp: Object.assign({}, response)

                            });
                            this.refresh();
                        }
                    });
            } else {
                this.restTableService.create(this.state.restTable)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                restTableDetailsSaveButtonRender: false, restTable: response,
                                restTableTemp: Object.assign({}, response)
                            });
                            this.refresh();
                        }
                    });
            }
        }

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let restTable = this.state.restTable;
        restTable[e.target.name] = value;
        this.setState({restTable: restTable});
    }

    __handleChangeParam = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let restTableParam = this.state.restTableParam;
        restTableParam[e.target.name] = value;
        this.setState({restTableParam: restTableParam});
    }

}

