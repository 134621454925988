import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import {Fieldset} from "primereact/fieldset";
import {AvGroup, AvForm} from "availity-reactstrap-validation";
import AvField from "../../shared/component/form/AvField";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import MemberUserService from "../../shared/service/MemberUserService";
import {PhoneField} from "../../shared/component/form/PhoneField";
import {Password} from "primereact/password";
import DateInput from "../../shared/component/form/DateInput";
import {toast} from "react-toastify";
import {PERSON_TITLE} from "../../shared/utils/Constants";

const defaultMemberUser = {
    userId: null,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    status: 'ACTIVE',
    mobilePhone:null,
    title:'MR'

};

export default class MemberUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            memberUser: defaultMemberUser,
            memberUserTemp:defaultMemberUser,
        }
        this.memberUserService = new MemberUserService();
    }

    componentDidMount() {
        this.memberUserService.findUser().then(response => {
                this.setState({
                    memberUser: response,
                    memberUserTemp: Object.assign({}, response)
                });
            }
        );

    }

    render() {
        return (
            <AvForm autocomplete="off">

                <Row>
                    <Col md={3}>
                    </Col>

                    <Col md={4}>


                        <Fieldset legend={"My Profile"}>


                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Mail
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            disabled={true}
                                            validate={{
                                                minLength: {
                                                    "value": 5, "errorMessage": "Not Valid Email"
                                                }, maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.memberUser.email}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="merchantBranch.tradingName">Title</Translate>
                                        </Label>
                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="title"
                                            disabled={!this.state.memberUserSaveButtonRender}
                                            value={this.state.memberUser.title}
                                            onChange={this.__handleChange}>
                                            {PERSON_TITLE.map(element => (<option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                        </AvField>
                                    </AvGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">First Name
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            disabled={!this.state.memberUserSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.memberUser.firstName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Last Name
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            disabled={!this.state.memberUserSaveButtonRender}
                                            validate={{
                                                maxLength: {
                                                    value: 255,
                                                    errorMessage: "You can enter up to 255 characters in this field."
                                                }
                                            }}
                                            value={this.state.memberUser.lastName}
                                            onChange={this.__handleChange}/>
                                    </AvGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="mobilePhone">
                                            <Translate contentKey="contactManagement.mobilePhone">Mobile
                                                Phone</Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <PhoneField kind={'mobile'}
                                                    disabled={!this.state.memberUserSaveButtonRender}
                                                    name="mobilePhone" errorMessage="Not Valid Phone Number"
                                                    value={this.state.memberUser.mobilePhone}
                                                    onChange={this.__handleChange}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>



                            {!this.state.memberUserSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                        onClick={() => {
                                            this.setState({memberUserSaveButtonRender: true});
                                        }}>
                                    <Translate contentKey="entity.action.add">Edit</Translate>
                                </Button>}
                            {this.state.memberUserSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                        onClick={() => {
                                            this.memberUserService.update(this.state.memberUser)
                                                .then((response) => {
                                                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                        this.setState({
                                                            memberUser: response, memberUserSaveButtonRender: false,
                                                            memberUserTemp: Object.assign({}, response)
                                                        });

                                                        localStorage.setItem("hsmUser", JSON.stringify(response));

                                                    }
                                                });
                                        }}>
                                    <Translate contentKey="entity.action.add">Save</Translate>
                                </Button>
                            }

                            { this.state.memberUserSaveButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                                    let obj1 = this.state.memberUserTemp;
                                    this.setState({
                                        memberUser: Object.assign({}, obj1), memberUserSaveButtonRender: false
                                    });
                                }}>
                                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                </Button>
                            }


                        </Fieldset>

                        <Fieldset legend={"Password"}>


                            <Row>
                                <Col md="12">
                                    <AvGroup>
                                        <Label for="email">
                                            <Translate contentKey="merchantBranch.tradingName">Password
                                            </Translate>
                                            <span style={{color: 'red'}}> (*)</span>
                                        </Label>
                                        <AvField
                                            value={this.state.memberUser.password}
                                            style={{webkitTextSecurity : "disc",textSecurity:"disc" }}
                                            name="password"
                                            type={"text"}
                                            className="form-control"
                                            disabled={!this.state.memberUserChangePasswordButtonRender}
                                            onChange={this.__handleChange}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>


                            {!this.state.memberUserChangePasswordButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                                        onClick={() => {
                                            this.setState({memberUserChangePasswordButtonRender: true});
                                        }}>
                                    <Translate contentKey="entity.action.add">Edit</Translate>
                                </Button>}
                            {this.state.memberUserChangePasswordButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px', marginBottom: '5px'}}
                                        onClick={() => {
                                            if (!GeneralUtils.checkPasswordFormat(this.state.memberUser.password)) {
                                                return toast.warn("Password must be at least one number, one lowercase letter, one uppercase letter, at least 8 characters");
                                            } else {
                                                this.memberUserService.changeUserPassword(this.state.memberUser.password)
                                                    .then((response) => {
                                                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                                            this.setState({
                                                                memberUserChangePasswordButtonRender: false
                                                            });

                                                        }
                                                    });
                                            }


                                        }}>
                                    <Translate contentKey="entity.action.add">Save</Translate>
                                </Button>
                            }


                            { this.state.memberUserChangePasswordButtonRender &&
                                <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}} onClick={() => {
                                    let obj1 = this.state.memberUserTemp;
                                    this.setState({
                                        memberUser: Object.assign({}, obj1), memberUserChangePasswordButtonRender: false
                                    });
                                }}>
                                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                                </Button>
                            }




                        </Fieldset>

                    </Col>
                    <Col md={3}>
                    </Col>
                </Row>

            </AvForm>
        );
    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let memberUser = this.state.memberUser;
        memberUser[e.target.name] = value;
        this.setState({memberUser: memberUser});

    }

}


