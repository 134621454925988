import React from 'react';
import {Button} from 'reactstrap';
import {AvForm} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import ProductColumn from "../../shared/component/dataTable/columns/ProductColumn.json";
import {Fieldset} from "primereact/fieldset";
import ProductService from "../../shared/service/ProductService";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import DataTable from "../../shared/component/dataTable/DataTable";
import Dialog from "../../shared/component/dialog/Dialog";
import DepartmentTransfer from "./DepartmentTransfer";
import ProductBulkSave from "./ProductBulkSave";

export default class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.productService = new ProductService();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        this.refDataTable.refresh();
    }

    render() {
        return (<div>
            <AvForm>
                {this.productList()}
            </AvForm>
        </div>);
    }

    productList() {

        var url = "product/product/all";

        return (<div>
            <Fieldset legend="Products">
                <Dialog visible={this.state.displayDialog}
                        header={""}
                        onHide={() => this.setState({displayDialog: false})}
                        style={GeneralUtils.getDialogStyleMerchantForDialog()}>

                    <ProductBulkSave ref={(elem) => this.refModelSave = elem}
                                     refreshDataTable={this.refreshDataTable}
                                        closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>

                {GeneralUtils.checkPrivilege("post_product/product") &&
                    <Button color="outline-primary" style={{marginBottom: '5px',marginRight: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("post_product/product") &&
                    <Button color="outline-primary" style={{marginBottom: '5px',marginRight: '5px'}} onClick={() => {
                        this.refModelSave.clearData();
                        this.setState({displayDialog: true});
                    }}>
                        <Translate contentKey="entity.action.add">Bulk Create</Translate>
                    </Button>
                }

                {GeneralUtils.checkPrivilege("put_product/product/all") &&
                    <DataTable ref={(elem) => this.refDataTable = elem} fields={ProductColumn.fields}
                                  objectName={ProductColumn.name}
                                  dataURL={url}
                                  selectionMode="single" onDataSelect={this.onDataSelect}
                                  loading={this.state.loading}/>
                }
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
        window.location.href = "#productSave?" + data.id;
    }

    addNew() {
        window.location.href = "#productSave?"
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    refreshDataTable = () => {
        this.refDataTable.refresh();
    }

}

