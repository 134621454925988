import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import DataTable from "../../shared/component/dataTable/DataTable";
import LicenseReportColumn from "../../shared/component/dataTable/columns/LicenseReportColumn.json";
import Dialog from "../../shared/component/dialog/Dialog";
import LicenseSave from "./LicenseSave";

export default class Licenses extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            licenseList: []
        }
    }

    componentDidMount() {
       this.refreshDataTable();
    }

    render() {
        return (<div>
            <AvForm>
                {this.merchantLicenseList()}
            </AvForm>
        </div>);
    }

    merchantLicenseList() {
        return (
            <div>
            <Fieldset legend="Licenses">
                <DataTable ref={(elem) => this.refDataTable = elem} fields={LicenseReportColumn.fields}
                              objectName={LicenseReportColumn.name}
                                dataURL="crm/merchant/license/all"
                              rows={10}
                              selectionMode="single" onDataSelect={this.onDataSelect}
                              loading={this.state.loading}/>


                <Dialog visible={this.state.displayDialog}
                        header={"License"}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '30vw',height: '35vw'}}>

                    <LicenseSave refreshDataTable={this.refreshDataTable} ref={(elem) => this.refModelSave = elem}
                                 closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>

            </Fieldset>
        </div>)
    }

    refreshDataTable = () => {
        this.closeDisplayDialog();
        this.refDataTable.refresh();
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));

        this.setState({
            displayDialog: true
        });

    }


    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let mailSettings = this.state.mailSettings;
        mailSettings[e.target.name] = value;
        this.setState({mailSettings: mailSettings});
    }

}

