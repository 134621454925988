import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import MerchantAccountService from "../../shared/service/MerchantAccountService";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import {
    DATA_UPLOAD_ENTITY
} from "../../shared/utils/Constants";
import MerchantBranchDataService from "../../shared/service/MerchantBranchDataService";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {Checkbox} from "primereact/checkbox";
import Loading from "../../shared/component/others/Loading";

const defaultUploadData = {
    merchantBranchId: null,
    uploadEntity: null,
    tax: false,
    department: false,
    productGroup: false,
    product: false,
    webMenu: false,
    mixAndMatch: false,
    floorPlanning: false,
    productTag: false,
    tag: false,
    supplier: false,
    tillCustomer: false,
    driver: false,
    transactions: false,
    definitions: false
};
export default class DataUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataUpload: Object.assign({}, defaultUploadData), dataSaveButtonRender: false,
            dataResetButtonRender: false,
            dataUploadTemp: Object.assign({}, defaultUploadData),
            merchantAccountList: [],
            merchantBranchList: [],
            entityList: DATA_UPLOAD_ENTITY

        }
        this.merchantAccountService = new MerchantAccountService();
        this.merchantBranchService = new MerchantBranchService();
        this.merchantBranchDataService = new MerchantBranchDataService();
    }

    componentDidMount() {

        this.merchantAccountService.findAll().then(response => this.setState({
            merchantAccountList: response
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.branchDetails()}
                {this.dataDetails()}
                {this.dataReset()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    branchDetails() {

        return (<div class="p-col-4">
            <Fieldset legend="Merchant Branch">

                <ModalConfirm header='Warning' ok='OK' onOkClick={this.saveData}
                              onCancelClick={() => this.setState({displayApproveConfirmDialog: false})}
                              show={this.state.displayApproveConfirmDialog}
                              message={this.state.noticeResponse}/>

                <ModalConfirm header='Warning' ok='OK' onOkClick={this.resetData}
                              onCancelClick={() => this.setState({displayResetConfirmDialog: false})}
                              show={this.state.displayResetConfirmDialog}
                              message={this.state.noticeResponse}/>

                <Row>
                    <Col>
                        <Row>
                            <Col md="12">

                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Merchant
                                            Account</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.dataSaveButtonRender && !this.state.dataResetButtonRender}
                                        className="form-control"
                                        name="merchantAccountId"
                                        value={this.state.merchantAccountId}
                                        onChange={this.__handleChangeMerchantAccount}>

                                        <option value='' key=''/>
                                        {this.state.merchantAccountList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.tradingName}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>

                            <Col md="12">

                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Merchant
                                            Branch</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.dataSaveButtonRender && !this.state.dataResetButtonRender}
                                        className="form-control"
                                        name="merchantBranchId"
                                        value={this.state.dataUpload.merchantBranchId}
                                        onChange={this.__handleChange}>

                                        <option value='' key=''/>
                                        {this.state.merchantBranchList.map(element => (
                                            <option value={element.id} key={element.id}>
                                                {element.tradingName}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>


                        </Row>
                    </Col>
                </Row>

            </Fieldset>
        </div>)
    };

    dataDetails() {

        return (<div class="p-col-4">
            <Fieldset legend="Data Upload">


                <Row>
                    <Col>

                        <Row>
                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("tax", e.checked)}
                                              name="tax"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.tax}
                                              checked={this.state.dataUpload.tax}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Tax&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("department", e.checked)}
                                              name="department"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.department}
                                              checked={this.state.dataUpload.department}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Department&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("productGroup", e.checked)}
                                              name="productGroup"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.productGroup}
                                              checked={this.state.dataUpload.productGroup}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Product Group&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("product", e.checked)}
                                              name="product"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.product}
                                              checked={this.state.dataUpload.product}></Checkbox>
                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Product&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("webMenu", e.checked)}
                                              name="webMenu"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.webMenu}
                                              checked={this.state.dataUpload.webMenu}></Checkbox>
                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Web Menu&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("mixAndMatch", e.checked)}
                                              name="mixAndMatch"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.mixAndMatch}
                                              checked={this.state.dataUpload.mixAndMatch}></Checkbox>
                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Mix & Match&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>


                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("floorPlanning", e.checked)}
                                              name="floorPlanning"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.floorPlanning}
                                              checked={this.state.dataUpload.floorPlanning}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Floor Planning&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("tag", e.checked)}
                                              name="tag"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.tag}
                                              checked={this.state.dataUpload.tag}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Tag&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("productTag", e.checked)}
                                              name="productTag"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.productTag}
                                              checked={this.state.dataUpload.productTag}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Product Tag&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("supplier", e.checked)}
                                              name="supplier"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.supplier}
                                              checked={this.state.dataUpload.supplier}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Supplier&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("driver", e.checked)}
                                              name="driver"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.driver}
                                              checked={this.state.dataUpload.driver}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Driver&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("tillCustomer", e.checked)}
                                              name="tillCustomer"
                                              disabled={!this.state.dataSaveButtonRender}
                                              value={this.state.dataUpload.tillCustomer}
                                              checked={this.state.dataUpload.tillCustomer}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Till Customer&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                        </Row>

                    </Col>
                </Row>

                {this.state.dataSaveButtonRender == false &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({dataSaveButtonRender: true, dataResetButtonRender: false});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {this.state.dataSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.showApproveConfirmDialog("Are you sure to sync data?");
                    }}>
                        <Translate contentKey="entity.action.add">Data Sync</Translate>
                    </Button>
                }

                {this.state.dataSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.dataUploadTemp;
                        this.setState({
                            dataUpload: Object.assign({}, obj1),
                            dataSaveButtonRender: false,
                            merchantAccountId: null,
                            dataResetButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>
        </div>)
    };

    dataReset() {

        return (<div class="p-col-4">
            <Fieldset legend="Data Reset">


                <Row>
                    <Col>

                        <Row>
                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("definitions", e.checked)}
                                              name="definitions"
                                              disabled={!this.state.dataResetButtonRender}
                                              value={this.state.dataUpload.definitions}
                                              checked={this.state.dataUpload.definitions}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Definitions&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>

                            <Col md="4">

                                <AvGroup>


                                    <Checkbox onChange={(e) => this.setChecked("transactions", e.checked)}
                                              name="transactions"
                                              disabled={!this.state.dataResetButtonRender}
                                              value={this.state.dataUpload.transactions}
                                              checked={this.state.dataUpload.transactions}></Checkbox>

                                    <Label for="firstName">
                                        <Translate contentKey="Thursday">&nbsp;Transactions&nbsp;</Translate>
                                    </Label>
                                </AvGroup>
                            </Col>


                        </Row>

                    </Col>
                </Row>

                {this.state.dataResetButtonRender == false &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({dataSaveButtonRender: false, dataResetButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {this.state.dataResetButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.showResetConfirmDialog("Are you sure to reset data?");
                    }}>
                        <Translate contentKey="entity.action.add">Data Reset</Translate>
                    </Button>
                }

                {this.state.dataResetButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.dataUploadTemp;
                        this.setState({
                            dataUpload: Object.assign({}, obj1),
                            dataSaveButtonRender: false,
                            merchantAccountId: null,
                            dataResetButtonRender: false
                        });
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>
                }
            </Fieldset>
        </div>)
    };


    showApproveConfirmDialog = (message) => {
        if (GeneralUtils.isNullOrEmpty(this.state.dataUpload.merchantBranchId)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            this.setState({
                displayApproveConfirmDialog: true,
                noticeResponse: message
            });
        }

    }

    showResetConfirmDialog = (message) => {
        if (GeneralUtils.isNullOrEmpty(this.state.dataUpload.merchantBranchId)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            this.setState({
                displayResetConfirmDialog: true,
                noticeResponse: message
            });
        }

    }

    saveData = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.dataUpload.merchantBranchId)
        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            this.setState({loading: true});
            this.merchantBranchDataService.upload(this.state.dataUpload)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            dataSaveButtonRender: false, dataUpload: Object.assign({}, defaultUploadData),
                            dataResetButtonRender: false,
                            dataUploadTemp: Object.assign({}, defaultUploadData),
                            merchantAccountId: null

                        });
                        this.setState({loading: false});
                    }
                });
        }

    }

    resetData = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.dataUpload.merchantBranchId)
        ) {
            return toast.warn("Please fill the required fields!");
        } else if (this.state.dataUpload.transactions == false && this.state.dataUpload.definitions == true) {
            return toast.warn("You can't reset only definitions ");
        } else {
            this.setState({loading: true});
            this.merchantBranchDataService.reset(this.state.dataUpload)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            dataSaveButtonRender: false,
                            dataResetButtonRender: false,
                            dataUpload: Object.assign({}, defaultUploadData),
                            dataUploadTemp: Object.assign({}, defaultUploadData),
                            merchantAccountId: null
                        });
                        this.setState({loading: false});
                    }
                });
        }

    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let dataUpload = this.state.dataUpload;
        dataUpload[e.target.name] = value;
        this.setState({dataUpload: dataUpload});
    }

    __handleChangeMerchantAccount = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;

        if (!GeneralUtils.isNullOrEmpty(value)) {
            this.merchantBranchService.findBranchesByAccountIdForCombo(value).then(response => this.setState({
                merchantBranchList: response
            }));
        } else {
            this.setState({merchantBranchList: []});
        }

        this.setState({merchantAccountId: value});

    }

    setChecked = (name, value) => {
        let dataUpload = this.state.dataUpload;
        dataUpload[name] = value;
        this.setState({dataUpload: dataUpload});
    }

}

