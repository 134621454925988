import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {PRICING_TYPE, SELECTION_TYPE, STATUS} from "../../shared/utils/Constants";
import ProductGroupColumn from "../../shared/component/dataTable/columns/ProductGroupColumn.json";
import ProductGroupProductColumn from "../../shared/component/dataTable/columns/ProductGroupProductColumn.json";
import ProductGroupService from "../../shared/service/ProductGroupService";
import DataTableWithButton from "../../shared/component/dataTable/DataTableWithButton";
import {InputNumber} from "primereact/inputnumber";
import {AutoComplete} from "primereact/autocomplete";
import ProductService from "../../shared/service/ProductService";
import DataTable from "../../shared/component/dataTable/DataTable";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";


const defaultProductGroup = {
    id: '',
    name: '',
    status: 'ACTIVE',
    price: null,
    selectionType: '',
    pricingType: '',
    products: [],
    zeroPriceLimit: null
};

export default class ProductGroupSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            productGroup: Object.assign({}, defaultProductGroup),
            productGroupTemp: Object.assign({}, defaultProductGroup),
            productGroupSaveButtonRender: true,
            defaultStatus: 'ACTIVE',
            barcode: '',
            name: '',
            filteredProducts: []
        }
        this.productGroupService = new ProductGroupService();
        this.productService = new ProductService();
    }

    componentDidMount() {
        let productGroup = defaultProductGroup;
        productGroup["products"] = [];
        this.setState({
            productGroup: Object.assign({}, defaultProductGroup),
            productGroupSaveButtonRender: false,
            barcode: '',
            selectedProduct: '',
            productGroupTemp: Object.assign({}, defaultProductGroup)
        });

        this.refresh();
    }

    refresh() {
        this.refDataTableList.refresh();
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.productGroup.id)) {
            this.productGroupService.delete(this.state.productGroup.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            productGroup: Object.assign({}, defaultProductGroup), taxDetailsSaveButtonRender: false,
                            productGroupTemp: Object.assign({}, defaultProductGroup)
                        });
                        this.refresh();
                    }
                });
        }

        return undefined;
    }

    render() {
        return (<div>
            <AvForm>
                {this.productGroups()}
            </AvForm>
        </div>);
    }

    showChangeStatusConfirmDialog = (value) => {
        let defaultStatus = 'PASSIVE';
        if (value) {
            defaultStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            defaultStatus: defaultStatus,
            noticeResponse: "Are you sure to delete?"
        });
    }


    productGroups() {
        var url = "product/productgroup/all";
        return (<div class="p-col-12">
            <Fieldset legend="Product Group Details">
                <Row>
                    <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                  onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                  show={this.state.displayShowStatusConfirmDialog}
                                  message={this.state.noticeResponse}/>

                    <Col md="12">
                        <Row>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="productGroup.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.productGroupSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.productGroup.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Selection Type</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.productGroupSaveButtonRender}
                                        className="form-control"
                                        name="selectionType"
                                        value={this.state.productGroup.selectionType}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {SELECTION_TYPE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Pricing Type</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.productGroupSaveButtonRender}
                                        className="form-control"
                                        name="pricingType"
                                        value={this.state.productGroup.pricingType}
                                        onChange={this.__handleChange}>
                                        <option value='' key=''/>
                                        {PRICING_TYPE.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Price</Translate>
                                    </Label>

                                    <InputNumber
                                        name="price"
                                        disabled={!this.state.productGroupSaveButtonRender}
                                        value={this.state.productGroup.price}
                                        onChange={this.__handleChange}
                                        mode="currency"
                                        className="table-responsive"
                                        currency="GBP" locale="en-US"
                                    />
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="producttradingName">Zero Price Limit</Translate>
                                    </Label>

                                    <InputNumber
                                        name="zeroPriceLimit"
                                        disabled={!this.state.productGroupSaveButtonRender}
                                        value={this.state.productGroup.zeroPriceLimit}
                                        onChange={this.__handleChange}
                                        className="table-responsive"
                                    />
                                </AvGroup>
                            </Col>

                        </Row>

                        <Fieldset legend="Select Product">

                            <Row>
                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Barcode</Translate>
                                        </Label>
                                        <AvField
                                            type="number"
                                            className="form-control"
                                            name="barcode"
                                            disabled={!this.state.productGroupSaveButtonRender}
                                            max="99999999999999"
                                            onKeyDown={this._handleKeyDown}
                                            value={this.state.barcode}
                                            onChange={this.__handleChangeBarcode}/>


                                    </AvGroup>

                                </Col>

                                <Col md="3">
                                    <AvGroup>
                                        <Label for="tradingName">
                                            <Translate contentKey="productGroup.tradingName">Name</Translate>
                                        </Label>
                                        <AutoComplete value={this.state.selectedProduct}
                                                      suggestions={this.state.filteredProducts}
                                                      completeMethod={this.searchProduct}
                                                      field="longname"
                                                      disabled={!this.state.productGroupSaveButtonRender}
                                                      onChange={(e) => {
                                                          let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
                                                          this.setState({selectedProduct: value});
                                                          if (!GeneralUtils.isNullOrEmpty(e.value.id)) {
                                                              this.setState({selectedProduct: e.value});
                                                              let data = e.value;
                                                              let products = this.state.productGroup.products;
                                                              if (!this.isExistsInList(products, data)) {
                                                                  products.push(data);
                                                                  let productGroup = this.state.productGroup;
                                                                  productGroup["products"] = products;
                                                                  this.setState({
                                                                      productGroup: productGroup,
                                                                      selectedProduct: ''
                                                                  });

                                                              }
                                                          }
                                                      }
                                                      }

                                        />


                                    </AvGroup>
                                </Col>


                            </Row>
                            <DataTableWithButton ref={(elem) => this.refDataTable = elem}
                                                 fields={ProductGroupProductColumn.fields}
                                                 objectName={ProductGroupProductColumn.name}
                                                 delete={this.deleteRow}
                                                 data={this.state.productGroup.products}
                                                 loading={this.state.loading}/>


                        </Fieldset>

                        {!GeneralUtils.isNullOrEmpty(this.state.productGroup.id) && GeneralUtils.checkPrivilege("post_product/productgroup") && this.state.productGroupSaveButtonRender == false &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({productGroupSaveButtonRender: true});
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}

                        {GeneralUtils.checkPrivilege("post_product/productgroup") && this.state.productGroupSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.saveProductGroup();
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>
                        }

                        {GeneralUtils.checkPrivilege("delete_product/productgroup/_id") && this.state.productGroupSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.productGroup.id) &&

                            <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                                this.showChangeStatusConfirmDialog(true)
                            }}>
                                <Translate contentKey="entity.action.add">Delete</Translate>
                            </Button>}

                        {GeneralUtils.checkPrivilege("post_product/productgroup") && this.state.productGroupSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let productGroup = defaultProductGroup;
                                productGroup["products"] = [];
                                this.setState({
                                    productGroup: Object.assign({}, productGroup),
                                    productGroupTemp: Object.assign({}, productGroup),
                                    productGroupSaveButtonRender: false,
                                    barcode: '',
                                    name: ''
                                });
                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>
                        }
                    </Col>


                </Row>


            </Fieldset>

            <Fieldset legend="Product Group List">
                {GeneralUtils.checkPrivilege("post_product/productgroup") &&
                    <Button color="outline-primary" style={{marginRight: '5px', marginBottom: '5px'}} onClick={() => {
                        this.addNew();
                    }}>
                        <Translate contentKey="entity.action.add">Add</Translate>
                    </Button>

                }

                {GeneralUtils.checkPrivilege("put_product/productgroup/all") &&
                    <DataTable ref={(elem) => this.refDataTableList = elem}
                               fields={ProductGroupColumn.fields}
                               objectName={ProductGroupColumn.name}
                               dataURL={url}
                               selectionMode="single" onDataSelect={this.onDataSelect}
                               loading={this.state.loading}/>

                }
            </Fieldset>


        </div>)
    };

    searchProduct = (event) => {
        setTimeout(() => {
            this.productService.searchProductsByName(event.query).then(response => {
                this.setState({filteredProducts: response});

            });
        }, 250);
    }

    deleteRow = (rowData) => {
        if (this.state.productGroupSaveButtonRender) {
            let products = this.state.productGroup.products;
            let newProducts = this.arrayRemove(products, rowData);

            let productGroup = this.state.productGroup;
            productGroup["products"] = newProducts;
            this.setState({productGroup: productGroup});
        }


    }

    arrayRemove = (arr, element) => {
        return arr.filter(function (el) {
            return el.id != element.id;
        });
    }

    onDataSelect = (data) => {
        this.productGroupService.findById(data.id).then(response => {
            this.setState({productGroup: response, productGroupSaveButtonRender: false, barcode: '', name: ''});

        });
    }
    saveProductGroup = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.productGroup.name) ||
            GeneralUtils.isNullOrEmpty(this.state.productGroup.selectionType) ||
            GeneralUtils.isNullOrEmpty(this.state.productGroup.pricingType)

        ) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (!GeneralUtils.isNullOrEmpty(this.state.productGroup.id)) {
                this.productGroupService.update(this.state.productGroup)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                productGroupSaveButtonRender: false,
                                productGroup: Object.assign({}, response),
                                productGroupTemp: Object.assign({}, response)

                            });
                            this.refresh();
                        }
                    });
            } else {
                this.productGroupService.create(this.state.productGroup)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                productGroupSaveButtonRender: false,
                                productGroup: Object.assign({}, response),
                                productGroupTemp: Object.assign({}, response)
                            });
                            this.refresh();
                        }
                    });
            }
        }
    }

    addNew() {
        let productGroup = defaultProductGroup;
        productGroup["products"] = [];
        this.setState({
            productGroupTemp: Object.assign({}, productGroup),
            productGroup: Object.assign({}, productGroup),
            productGroupSaveButtonRender: true,
            barcode: '',
            selectedProduct: ''
        });


    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let productGroup = this.state.productGroup;
        productGroup[e.target.name] = value;
        this.setState({productGroup: productGroup});
    }

    findByBarcode() {

        if (!GeneralUtils.isNullOrEmpty(this.state.barcode)) {
            this.productService.findProductByBarcode(this.state.barcode)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        let data = response;
                        let products = this.state.productGroup.products;
                        if (!this.isExistsInList(products, data)) {
                            products.push(data);
                            let productGroup = this.state.productGroup;
                            productGroup["products"] = products;
                            this.setState({productGroup: productGroup});

                        }
                    } else {
                        return toast.warn("Barcode not Found!");
                    }

                });
        }
        this.setState({barcode: ''});

    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.findByBarcode();
        }
    }

    __handleChangeBarcode = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({barcode: value});
    }

    isExistsInList = (assetList, data) => {
        var existsInList = false;
        for (var i = 0; i < assetList.length; i++) {
            if (data.id == assetList[i].id) {
                existsInList = true;
            }
        }
        return existsInList;
    }

}

