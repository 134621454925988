import React from 'react';
import {Col, Label, Row} from 'reactstrap';
import {Button} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {PhoneField} from "../../shared/component/form/PhoneField";
import {toast} from "react-toastify";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import {
    COUNTRY, END_OF_DAY_TIME, ORDER_NOTIFICATION_TUNNEL_OPTIONS,
    PAGE_MODE, PAYMENT_INTEGRATION_PROVIDER, SECTOR,
    STATUS,
    TABLE_ORDER_PAYMENT_OPTIONS,
    WEB_ORDER_PAYMENT_OPTIONS, YESNO
} from "../../shared/utils/Constants";
import {TabPanel, TabView} from "primereact/tabview";
import {FileUpload} from "primereact/fileupload";
import {InputSwitch} from "primereact/inputswitch";
import LicenseColumn from "../../shared/component/dataTable/columns/LicenseColumn.json";
import BranchCredentialsColumn from "../../shared/component/dataTable/columns/BranchCredentialsColumn.json";
import CreditColumn from "../../shared/component/dataTable/columns/CreditColumn.json";
import DataTableAll from "../../shared/component/dataTable/DataTableAll";
import LicenseSave from "./LicenseSave";
import Dialog from "../../shared/component/dialog/Dialog";
import {Button as ButtonSearch} from 'primereact/button';
import PageSettingsService from "../../shared/service/PageSettingsService";
import LicenseService from "../../shared/service/LicenseService";
import MerchantBranchService from "../../shared/service/MerchantBranchService";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";
import MerchantBranchSettingsService from "../../shared/service/MerchantBranchSettingsService";
import Loading from "../../shared/component/others/Loading";
import "./styles.css";
import MerchantBranchCredentialsService from "../../shared/service/MerchantBranchCredentialsService";
import MerchantBranchCreditService from "../../shared/service/MerchantBranchCreditService";
import CredentialsSave from "./CredentialsSave";
import CreditSave from "./CreditSave";
import MailSettingsSave from "../settings/MailSettingsSave";
import CurrentUserService from "../../shared/service/CurrentUserService";

const defaultMerchantBranch = {
    id: '',
    tradingName: '',
    businessName: '',
    vatNo: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    emailAddress: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    state: '',
    city: '',
    postCode: '',
    country: 'United Kingdom',
    merchantAccountId: '',
    status: 'ACTIVE',
    domainName: '',
    domainAlias: '',
    sector: 'RETAIL',
    subsector: ''
};

const defaultPageSettings = {
    id: '',
    merchantBranchId: '',
    footerFileType: '',
    footerFileName: 'Choose',
    logoFileType: '',
    logoFileName: 'Choose',
    headerFileType: '',
    headerFileName: 'Choose'
};

const defaultMerchantBranchSettings = {
    id: '',
    merchantBranchId: '',
    tableOrderPaymentOptions: null,
    webOrderPaymentOptions: null,
    notificationTunnel:null,
    autoOrderConfirm: true,
    autoKitchen: true,
    autoInsideDelivery: true,
    autoOutsideDelivery: false,
    autoDispatch: true,
    email: '',
    endOfDayHour: "00",
    isAutoEndOfDay: true,
    provider: "DOJO",
    jsUrl: '',
    merchantUrl: '',
    baseUrl: '',
    jwt: ''
};


export default class MerchantBranchSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageMode: PAGE_MODE.CREATE,
            merchantBranch: defaultMerchantBranch,
            merchantBranchSettings: defaultMerchantBranchSettings,
            merchantBranchSettingsTemp: defaultMerchantBranchSettings,
            pageSettings: defaultPageSettings,
            merchantBranchTemp: defaultMerchantBranch,
            pageSettingsTemp: defaultPageSettings,
            totalCredit: 0,
            totalCreditUsed: 0,
            accountDetailsContactSaveButtonRender: false,
            branchDetailsSaveButtonRender: false,
            licenseSaveButtonRender1: false,
            licenseSaveButtonRender2: false,
            licenseSaveButtonRender3: false,
            paymentSettingsSaveButtonRender: false,
            renderHeaderShowButton: false,
            renderFooterShowButton: false,
            renderLogoShowButton: false,
            disabledContactDetailsTab: true,
            disabledAddressDetailsTab: true,
            generalSettingsSaveButtonRender: false,
            activeIndex: 0,
            loading: false,
            licenseList: [],
            credentialList: [],
            creditList: []
        }
        this.merchantBranchService = new MerchantBranchService();
        this.pageSettingsService = new PageSettingsService();
        this.merchantBranchSettingsService = new MerchantBranchSettingsService();
        this.licenseService = new LicenseService();
        this.merchantBranchCredentialsService = new MerchantBranchCredentialsService();
        this.merchantBranchCreditService = new MerchantBranchCreditService();
        this.currentUserService = new CurrentUserService();
    }

    componentDidMount() {
        let id = window.location.href.split('?')[1];
        let merchantAccountId = window.location.href.split('?')[2];

        if (!GeneralUtils.__isValueEmpty(id)) {
            this.merchantBranchService.findById(id).then(response => {
                this.setState({
                    merchantBranch: response,
                    merchantBranchTemp: Object.assign({}, response),
                    branchId: id,
                    pageMode: PAGE_MODE.UPDATE,
                    merchantAccountId: response.merchantAccountId,
                    branchDetailsSaveButtonRender: false,
                    branchDetailsContactSaveButtonRender: false,
                    branchDetailsAddressSaveButtonRender: false,
                    disabledContactDetailsTab: false,
                    disabledAddressDetailsTab: false,
                })
                this.licenseService.findByBranchId(id).then(response => this.setState({
                    licenseList: response
                }));

                this.merchantBranchCredentialsService.findByBranchId(id).then(response => this.setState({
                    credentialList: response
                }));

                this.refreshDataTableCreditById(id);

            });

            this.pageSettingsService.findByBranchId(id).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({
                        pageSettings: response, pageSettingsTemp: Object.assign({}, response)
                    })

                } else {
                    this.setState({
                        pageSettings: defaultPageSettings, pageSettingsTemp: Object.assign({}, defaultPageSettings)
                    })
                }
            })

            this.merchantBranchSettingsService.findByBranchId(id).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    this.setState({
                        merchantBranchSettings: this.generateBranchSettingsDataFromResponse(response),
                        merchantBranchSettingsTemp: Object.assign({}, this.generateBranchSettingsDataFromResponse(response))
                    })
                } else {
                    this.setState({
                        merchantBranchSettings: defaultMerchantBranchSettings,
                        merchantBranchSettingsTemp: Object.assign({}, defaultMerchantBranchSettings)
                    })
                }
            })
        } else {
            this.clearData(merchantAccountId);
        }
    }

    clearData = (merchantAccountId) => {
        let obj1 = Object.assign({}, defaultMerchantBranch);
        let obj2 = Object.assign({}, defaultMerchantBranch);

        this.currentUserService.getCurrentAdminOrMemberUser().then(response => this.setState({
            pageMode: PAGE_MODE.CREATE,
            merchantBranch: obj1,
            merchantBranchTemp: obj2,
            disabledContactDetailsTab: true,
            disabledAddressDetailsTab: true,
            branchId: '',
            merchantAccountId: merchantAccountId,
            branchDetailsSaveButtonRender: true,
            branchDetailsContactSaveButtonRender: false,
            branchDetailsAddressSaveButtonRender: false
        }));
    }

    render() {
        return (<div>
            <AvForm>
                {this.merchantBranchDetails()}
                {this.merchantCredentialAndLicenseList()}
                {this.settings()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    setCheckedStatus = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranch.id)) {
            this.merchantBranchService.updateStatus(this.state.merchantBranch.id, this.state.merchantStatus)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            merchantBranch: response,
                            merchantBranchTemp: Object.assign({}, response),
                            branchDetailsSaveButtonRender: false
                        });
                    }
                });
        } else {
            let merchantBranch = this.state.merchantBranch;
            merchantBranch["status"] = this.state.merchantBranch.status == "ACTIVE" ? "PASSIVE" : "ACTIVE";
            this.setState({merchantBranch: merchantBranch, merchantBranchTemp: Object.assign({}, merchantBranch)});
        }
        return undefined;
    }

    delete = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.merchantBranch.id)) {
            this.merchantBranchService.delete(this.state.merchantBranch.id)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        window.location.href = '#merchantAccountList'
                    }
                });
        }

        return undefined;
    }

    showChangeDeleteConfirmDialog = (value) => {
        this.setState({
            displayShowDeleteConfirmDialog: true,
            noticeResponse: "Are you sure to delete?"
        });
    }

    showChangeStatusConfirmDialog = (value) => {
        let merchantStatus = 'PASSIVE';
        if (value) {
            merchantStatus = 'ACTIVE'
        }
        this.setState({
            displayShowStatusConfirmDialog: true,
            merchantStatus: merchantStatus,
            noticeResponse: "You are about to change the status. Are you sure?"
        });
    }


    merchantBranchDetails() {
        return (<Fieldset legend="Branch Details">
            <TabView activeIndex={this.state.activeIndex}
                     onTabChange={(e) => this.setState({activeIndex: e.index})}>
                <TabPanel header="Branch Details">
                    <Row>
                        <Col md="2">
                            <Fieldset legend="Status">
                                <Col md="3">
                                </Col>
                                <Col md="9">
                                    <Row>

                                        <AvField
                                            type="select"
                                            className="form-control"
                                            name="status"
                                            disabled={true}
                                            value={this.state.merchantBranch.status}
                                            onChange={this.__handleChangeBranch}>
                                            {STATUS.map(element => (<option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                        </AvField>

                                        <ModalConfirm header='Warning' ok='OK' onOkClick={this.setCheckedStatus}
                                                      onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                                      show={this.state.displayShowStatusConfirmDialog}
                                                      message={this.state.noticeResponse}/>

                                        <ModalConfirm header='Warning' ok='OK' onOkClick={this.delete}
                                                      onCancelClick={() => this.setState({displayShowDeleteConfirmDialog: false})}
                                                      show={this.state.displayShowDeleteConfirmDialog}
                                                      message={this.state.noticeResponse}/>
                                        <InputSwitch
                                            checked={this.state.merchantBranch.status == "ACTIVE" ? true : false}
                                            tooltip={"Change Status"}
                                            disabled={!this.state.branchDetailsSaveButtonRender}
                                            onChange={(e) => this.showChangeStatusConfirmDialog(e.value)}/>

                                    </Row>

                                </Col>
                            </Fieldset>

                        </Col>

                        <Col md="2">
                            <AvGroup>
                                <Label for="tradingName">
                                    <Translate contentKey="merchantBranch.tradingName">Trading Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="tradingName"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantBranch.tradingName}
                                    onChange={this.__handleChangeBranch}/>
                            </AvGroup>
                        </Col>
                        <Col md="2">
                            <AvGroup>
                                <Label for="businessName">
                                    <Translate contentKey="merchantAccount.businessName">Business Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="businessName"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantBranch.businessName}
                                    onChange={this.__handleChangeBranch}/>
                            </AvGroup>
                        </Col>

                        <Col md="2">
                            <AvGroup>
                                <Label for="vatNo">
                                    <Translate contentKey="merchantAccount.businessName">Vat No</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="vatNo"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantBranch.vatNo}
                                    onChange={this.__handleChangeBranch}/>
                            </AvGroup>
                        </Col>


                        <Col md="2">
                            <AvGroup>
                                <Label for="vatNo">
                                    <Translate contentKey="merchantAccount.businessName">Domain Name</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="domainName"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantBranch.domainName}
                                    onChange={this.__handleChangeBranch}/>
                            </AvGroup>
                        </Col>

                        <Col md="2">
                            <AvGroup>
                                <Label for="vatNo">
                                    <Translate contentKey="merchantAccount.businessName">Domain Alias</Translate>
                                </Label>
                                <AvField
                                    type="text"
                                    className="form-control"
                                    name="domainAlias"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    validate={{
                                        maxLength: {
                                            value: 255,
                                            errorMessage: "You can enter up to 255 characters in this field."
                                        }
                                    }}
                                    value={this.state.merchantBranch.domainAlias}
                                    onChange={this.__handleChangeBranch}/>
                            </AvGroup>
                        </Col>

                        <Col md="2">
                            <AvGroup>
                                <Label for="lastName">
                                    <Translate contentKey="merchantAccount.lastName">Sector</Translate>
                                    <span style={{color: 'red'}}> (*)</span>
                                </Label>
                                <AvField
                                    type="select"
                                    className="form-control"
                                    name="sector"
                                    disabled={!this.state.branchDetailsSaveButtonRender}
                                    value={this.state.merchantBranch.sector}
                                    onChange={this.__handleChangeBranch}>
                                    {SECTOR.map(element => (<option value={element.value} key={element.value}>
                                        {element.name}
                                    </option>))}
                                </AvField>
                            </AvGroup>
                        </Col>


                    </Row>


                    {!this.state.branchDetailsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.setState({branchDetailsSaveButtonRender: true});
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.branchDetailsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.saveBranch();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>}

                    {this.state.branchDetailsSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            let obj1 = this.state.merchantBranchTemp;

                            if (GeneralUtils.isNullOrEmpty(obj1.id)) {
                                window.history.back();
                            } else {
                                this.setState({
                                    merchantBranch: Object.assign({}, obj1), branchDetailsSaveButtonRender: false
                                });
                            }


                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>}
                    {this.state.branchDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.merchantBranch.id) &&

                        <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                            this.showChangeDeleteConfirmDialog(true)
                        }}>
                            <Translate contentKey="entity.action.add">Delete</Translate>
                        </Button>}


                </TabPanel>
                <TabPanel disabled={this.state.disabledContactDetailsTab} header="Branch Contact Details">
                    {this.state.merchantBranch.id != '' &&

                        <Row>
                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">First Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        disabled={!this.state.branchDetailsContactSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.firstName}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>
                            <Col md="2">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">Last Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        disabled={!this.state.branchDetailsContactSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.lastName}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Email</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="email"
                                        className="form-control"
                                        name="emailAddress"
                                        disabled={!this.state.branchDetailsContactSaveButtonRender}
                                        validate={{
                                            minLength: {
                                                "value": 5, "errorMessage": "Not Valid Email"
                                            }, maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.emailAddress}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Mobile
                                            Phone</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <PhoneField kind={'mobile'}
                                                disabled={!this.state.branchDetailsContactSaveButtonRender}
                                                name="mobilePhone" errorMessage="Not Valid Phone Number"
                                                value={this.state.merchantBranch.mobilePhone}
                                                onChange={this.__handleChangeBranch}
                                    />
                                </AvGroup>
                            </Col>


                        </Row>


                    }

                    {this.state.branchDetailsContactSaveButtonRender == false &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.setState({
                                branchDetailsContactSaveButtonRender: true
                            });
                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.branchDetailsContactSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.saveMerchantBranchContact();
                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>}

                    {this.state.branchDetailsContactSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            let obj1 = this.state.merchantBranchTemp;
                            this.setState({
                                merchantBranch: Object.assign({}, obj1), branchDetailsContactSaveButtonRender: false
                            });


                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>}

                </TabPanel>
                <TabPanel disabled={this.state.disabledAddressDetailsTab} header="Branch Address Details">
                    {this.state.merchantBranch.id != '' &&

                        <Row>
                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">Address1</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="address1"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.address1}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">Address2</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="address2"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.address2}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">Address3</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="address3"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.address3}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="merchantAccount.firstName">Address4</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="address4"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.address4}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">State</Translate>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="state"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.state}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">Post Code</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="postCode"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.postCode}
                                        onChange={this.__handleChangeBranch}/>

                                </AvGroup>
                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">City</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="city"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.city}
                                        onChange={this.__handleChangeBranch}/>
                                </AvGroup>

                            </Col>

                            <Col md="2">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="merchantAccount.lastName">Country</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="country"
                                        disabled={!this.state.branchDetailsAddressSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranch.country}
                                        onChange={this.__handleChangeBranch}>
                                        {COUNTRY.map(element => (<option value={element.value} key={element.value}>
                                            {element.name}
                                        </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>
                        </Row>

                    }
                    {this.state.branchDetailsAddressSaveButtonRender == false &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.setState({
                                branchDetailsAddressSaveButtonRender: true
                            });

                        }}>
                            <Translate contentKey="entity.action.add">Edit</Translate>
                        </Button>}
                    {this.state.branchDetailsAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.saveMerchantBranchAddress();

                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>}

                    {this.state.branchDetailsAddressSaveButtonRender &&
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {

                            let obj1 = this.state.merchantBranchTemp;
                            this.setState({
                                merchantBranch: Object.assign({}, obj1), branchDetailsAddressSaveButtonRender: false
                            });

                        }}>
                            <Translate contentKey="entity.action.cancel">Cancel</Translate>
                        </Button>}
                </TabPanel>
            </TabView>


        </Fieldset>)
    }

    downloadDocument = (type) => {
        if (!GeneralUtils.isNullOrEmpty(this.state.pageSettings.id)) {
            this.pageSettingsService.download(this.state.pageSettings.id, type).then(response => {
                GeneralUtils.convertToDownloadableFile(response);
            });
        }
    }

    generateBranchSettingsDataFromResponse(response) {
        var tableOrderArrayList = new Array();
        if (!GeneralUtils.isNullOrEmpty(response.tableOrderPaymentOptions)) {
            var nameArr = response.tableOrderPaymentOptions.split(',');

            for (var i = 0; i < nameArr.length; i++) {
                let data1 = {
                    name: nameArr[i],
                    code: nameArr[i]
                };
                tableOrderArrayList.push(data1);
            }

        }
        var webOrderArrayList = new Array();
        if (!GeneralUtils.isNullOrEmpty(response.webOrderPaymentOptions)) {
            var nameArr = response.webOrderPaymentOptions.split(',');

            for (var i = 0; i < nameArr.length; i++) {
                let data1 = {
                    name: nameArr[i],
                    code: nameArr[i]
                };
                webOrderArrayList.push(data1);
            }

        }

        var notificationTunnelArrayList = new Array();
        if (!GeneralUtils.isNullOrEmpty(response.notificationTunnel)) {
            var nameArr = response.notificationTunnel.split(',');

            for (var i = 0; i < nameArr.length; i++) {
                let data1 = {
                    name: nameArr[i],
                    code: nameArr[i]
                };
                notificationTunnelArrayList.push(data1);
            }

        }

        let data = {
            id: response.id,
            email: response.email,
            endOfDayHour: response.endOfDayHour,
            isAutoEndOfDay: response.isAutoEndOfDay,
            merchantBranchId: response.merchantBranchId,
            autoOrderConfirm: response.autoOrderConfirm,
            autoKitchen: response.autoKitchen,
            autoInsideDelivery: response.autoInsideDelivery,
            autoOutsideDelivery: response.autoOutsideDelivery,
            autoDispatch: response.autoDispatch,
            provider: response.provider,
            jsUrl: response.jsUrl,
            merchantUrl: response.merchantUrl,
            baseUrl: response.baseUrl,
            jwt: response.jwt,
            password: response.password,
            tableOrderPaymentOptions: tableOrderArrayList,
            webOrderPaymentOptions: webOrderArrayList,
            notificationTunnel: notificationTunnelArrayList,

        };
        return data;
    }

    generateBranchSettingsDataFromState() {
        let data = {
            id: this.state.merchantBranchSettings.id,
            email: this.state.merchantBranchSettings.email,
            endOfDayHour: this.state.merchantBranchSettings.endOfDayHour,
            isAutoEndOfDay: this.state.merchantBranchSettings.isAutoEndOfDay,
            autoOrderConfirm: this.state.merchantBranchSettings.autoOrderConfirm,
            autoKitchen: this.state.merchantBranchSettings.autoKitchen,
            autoInsideDelivery: this.state.merchantBranchSettings.autoInsideDelivery,
            autoOutsideDelivery: this.state.merchantBranchSettings.autoOutsideDelivery,
            autoDispatch: this.state.merchantBranchSettings.autoDispatch,
            merchantBranchId: this.state.merchantBranch.id,
            provider: this.state.merchantBranchSettings.provider,
            jsUrl: this.state.merchantBranchSettings.jsUrl,
            merchantUrl: this.state.merchantBranchSettings.merchantUrl,
            baseUrl: this.state.merchantBranchSettings.baseUrl,
            jwt: this.state.merchantBranchSettings.jwt,
            password: this.state.merchantBranchSettings.password,
            tableOrderPaymentOptions: !GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.tableOrderPaymentOptions) ? this.state.merchantBranchSettings.tableOrderPaymentOptions.map(x => x.code).join(",") : null,
            webOrderPaymentOptions: !GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.webOrderPaymentOptions) ? this.state.merchantBranchSettings.webOrderPaymentOptions.map(x => x.code).join(",") : null,
            notificationTunnel: !GeneralUtils.isNullOrEmpty(this.state.merchantBranchSettings.notificationTunnel) ? this.state.merchantBranchSettings.notificationTunnel.map(x => x.code).join(",") : null
        };
        return data;
    }

    merchantBranchSettingsSave = () => {
        let data = this.generateBranchSettingsDataFromState();

        if(GeneralUtils.isNullOrEmpty(data.notificationTunnel)){
            return toast.warn("Please select notification tunnel");
        }
        else{
            if (!GeneralUtils.isNullOrEmpty(data.id)) {
                data.userType = "admin";
                this.merchantBranchSettingsService.update(data).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            merchantBranchSettings: this.generateBranchSettingsDataFromResponse(response),
                            merchantBranchSettingsTemp: Object.assign({}, this.generateBranchSettingsDataFromResponse(response)),
                            merchantBranchSettingsSaveButtonRender: false,
                            generalSettingsSaveButtonRender: false,
                            paymentSettingsSaveButtonRender: false
                        })
                    }
                });

            } else {
                this.merchantBranchSettingsService.create(data).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            merchantBranchSettings: this.generateBranchSettingsDataFromResponse(response),
                            merchantBranchSettingsTemp: Object.assign({}, this.generateBranchSettingsDataFromResponse(response)),
                            merchantBranchSettingsSaveButtonRender: false,
                            paymentSettingsSaveButtonRender: false
                        })

                    }
                });
            }
        }



    }


    pageSettingsSave = () => {
        let header = this.refUploadInputHeader.files;
        let footer = this.refUploadInputFooter.files;
        let logo = this.refUploadInputLogo.files;
        let data = {
            id: this.state.pageSettings.id,
            merchantBranchId: this.state.merchantBranch.id
        };

        if (GeneralUtils.isNullOrEmpty(this.state.pageSettings.id)) {
            if (GeneralUtils.isNullOrEmpty(header) || GeneralUtils.isNullOrEmpty(header.length) || GeneralUtils.isNullOrEmpty(logo) || GeneralUtils.isNullOrEmpty(logo.length) || GeneralUtils.isNullOrEmpty(footer) || GeneralUtils.isNullOrEmpty(footer.length)
            ) {
                return toast.warn("Please fill the Header Web Image, Header Mobile Image and Logo!");

            } else {
                this.pageSettingsService.createPageSettings(header, footer, logo, data).then(response => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            pageSettings: response,
                            pageSettingsTemp: Object.assign({}, response)
                        })
                    }
                });
            }
        } else {
            this.pageSettingsService.updatePageSettings(data.id, data).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response.id)) {
                    let formData = new FormData();
                    let types = "";
                    if (!GeneralUtils.isNullOrEmpty(header) && !GeneralUtils.isNullOrEmpty(header.length)) {
                        formData.append("file", header[0]);
                        types = types + "HEADER;";
                    }
                    if (!GeneralUtils.isNullOrEmpty(footer) && !GeneralUtils.isNullOrEmpty(footer.length)) {
                        formData.append("file", footer[0]);
                        types = types + "MOBILE;";
                    }

                    if (!GeneralUtils.isNullOrEmpty(logo) && !GeneralUtils.isNullOrEmpty(logo.length)) {
                        formData.append("file", logo[0]);
                        types = types + "LOGO;";
                    }
                    formData.append("types", types);

                    if (types.length > 0) {
                        this.pageSettingsService.uploadDocument(data.id, formData).then(response => {
                            this.setState({
                                pageSettings: response,
                                pageSettingsTemp: Object.assign({}, response)
                            })
                        });
                    } else {
                        this.setState({
                            pageSettings: response,
                            pageSettingsTemp: Object.assign({}, response)
                        })
                    }

                }
            });
        }
    }

    refreshDataTable = () => {
        this.closeDisplayDialog();
        this.licenseService.findByBranchId(this.state.merchantBranch.id).then(response => this.setState({
            licenseList: response
        }));
    }

    refreshDataTableCredentials = () => {
        this.closeDisplayDialogCredentials();
        this.merchantBranchCredentialsService.findByBranchId(this.state.merchantBranch.id).then(response => this.setState({
            credentialList: response
        }));
    }

    refreshDataTableCredit = () => {
        this.refreshDataTableCreditById(this.state.merchantBranch.id);
    }
    refreshDataTableCreditById = (id) => {
        this.closeDisplayDialogCredit();

        this.merchantBranchCreditService.findByBranchId(id).then(response => {
            var totalCredit = 0;
            var totalCreditUsed = 0;
            for (var i = 0; i < response.length; i++) {
                if (response[i].status == "ACTIVE") {
                    totalCredit = totalCredit + response[i].creditValue;
                    totalCreditUsed = totalCreditUsed + response[i].creditUsed;
                }


            }
            this.setState({
                totalCredit: totalCredit,
                totalCreditUsed: totalCredit - totalCreditUsed,
                creditList: response
            })
        });

    }

    settings = () => {
        return (this.state.merchantBranch.id != '' && this.state.pageMode == PAGE_MODE.UPDATE && <div>

            <Fieldset legend="Settings">
                <TabView>
                    <TabPanel header="Page Settings">
                        <Row>
                            <Col md="4">
                                <Fieldset legend="Header Web Image (1900 x 360)">
                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    accept="image/*"
                                                    maxFileSize={5000000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 5MB."
                                                    onSelect={this.headerUploader}
                                                    chooseLabel={this.state.pageSettings.headerFileName == null ? "Choose" : this.state.pageSettings.headerFileName}
                                                    disabled={false}
                                                    ref={elem => this.refUploadInputHeader = elem}
                                                    mode="basic"></FileUpload>

                                        {(!GeneralUtils.isNullOrEmpty(this.state.pageSettings.id) || this.state.renderHeaderShowButton) &&
                                            <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}}
                                                          onClick={() => {

                                                              try {
                                                                  GeneralUtils.previewDocument(this.refUploadInputHeader);
                                                              } catch (e) {
                                                                  this.downloadDocument("HEADER");
                                                              }
                                                          }}>
                                            </ButtonSearch>}
                                    </Row>
                                </Fieldset>
                            </Col>
                            <Col md="4">
                                <Fieldset legend="Header Mobile Image(400 x 300)">

                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    maxFileSize={5000000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 5MB."
                                                    accept="image/*"
                                                    onSelect={this.footerUploader}
                                                    chooseLabel={this.state.pageSettings.footerFileName == null ? "Choose" : this.state.pageSettings.footerFileName}
                                                    disabled={false}
                                                    ref={elem => this.refUploadInputFooter = elem}
                                                    mode="basic"></FileUpload>


                                        {(!GeneralUtils.isNullOrEmpty(this.state.pageSettings.id) || this.state.renderFooterShowButton) &&
                                            <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}}
                                                          onClick={() => {

                                                              try {
                                                                  GeneralUtils.previewDocument(this.refUploadInputFooter);
                                                              } catch (e) {
                                                                  this.downloadDocument("MOBILE");
                                                              }
                                                          }}>
                                            </ButtonSearch>}
                                    </Row>
                                </Fieldset>
                            </Col>


                            <Col md="4">
                                <Fieldset legend="Logo (90 x 90)">
                                    <Row>
                                        <Col md="1">
                                        </Col>
                                        <FileUpload name="demo"
                                                    maxFileSize={5000000}
                                                    onValidationFailError={GeneralUtils.onValidationFailErrorFile}
                                                    invalidFileSizeMessageDetail="Maximum upload size is 5MB."
                                                    accept="image/*"
                                                    chooseLabel={this.state.pageSettings.logoFileName == null ? "Choose" : this.state.pageSettings.logoFileName}
                                                    onSelect={this.logoUploader}
                                                    disabled={false}
                                                    ref={elem => this.refUploadInputLogo = elem}
                                                    mode="basic"></FileUpload>

                                        {(!GeneralUtils.isNullOrEmpty(this.state.pageSettings.id) || this.state.renderLogoShowButton) &&
                                            <ButtonSearch icon="pi pi-search" style={{marginLeft: '5px'}}
                                                          onClick={() => {

                                                              try {
                                                                  GeneralUtils.previewDocument(this.refUploadInputLogo);
                                                              } catch (e) {
                                                                  this.downloadDocument("LOGO");
                                                              }
                                                          }}>
                                            </ButtonSearch>}
                                    </Row>
                                </Fieldset>
                            </Col>


                        </Row>
                        <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                            this.pageSettingsSave();

                        }}>
                            <Translate contentKey="entity.action.add">Save</Translate>
                        </Button>


                    </TabPanel>
                    <TabPanel header="Order Settings">


                        <Row>

                            <Col md={4}>
                                <Fieldset legend={"Order Payment Settings"}>
                                    <Row>
                                        <Col md={6}>
                                            <AvGroup>
                                                <Label for="lastName">
                                                    <Translate contentKey="merchantAccount.lastName">Table Order Payment
                                                        Options</Translate>
                                                </Label>
                                                <MultiSelect display="chip"
                                                             value={this.state.merchantBranchSettings.tableOrderPaymentOptions}
                                                             name="tableOrderPaymentOptions"
                                                             disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                             onChange={this.__handleChangeMerchantBranchSettings}
                                                             optionLabel="name" options={TABLE_ORDER_PAYMENT_OPTIONS}

                                                />
                                            </AvGroup>
                                        </Col>

                                        <Col md="6">
                                            <AvGroup>
                                                <Label for="lastName">
                                                    <Translate contentKey="merchantAccount.lastName">Web Order Payment
                                                        Options</Translate>
                                                </Label>
                                                <MultiSelect display="chip"
                                                             value={this.state.merchantBranchSettings.webOrderPaymentOptions}
                                                             name="webOrderPaymentOptions"
                                                             disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                             onChange={this.__handleChangeMerchantBranchSettings}
                                                             optionLabel="name" options={WEB_ORDER_PAYMENT_OPTIONS}

                                                />
                                            </AvGroup>
                                        </Col>

                                    </Row>

                                </Fieldset>

                            </Col>

                            <Col md={8}>
                                <Fieldset legend={"Order Flow Settings"}>

                                    <Row>
                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="firstName">
                                                    <Translate contentKey="productfirstName">Auto Order
                                                        Confirm?&nbsp;</Translate>
                                                </Label>

                                                <Checkbox
                                                    onChange={(e) => this.setChecked("autoOrderConfirm", e.checked)}
                                                    name="autoOrderConfirm"
                                                    disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                    value={this.state.merchantBranchSettings.autoOrderConfirm}
                                                    checked={this.state.merchantBranchSettings.autoOrderConfirm}></Checkbox>

                                            </AvGroup>
                                        </Col>

                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="firstName">
                                                    <Translate contentKey="productfirstName">Auto
                                                        Kitchen?&nbsp;</Translate>
                                                </Label>

                                                <Checkbox onChange={(e) => this.setChecked("autoKitchen", e.checked)}
                                                          name="autoKitchen"
                                                          disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                          value={this.state.merchantBranchSettings.autoKitchen}
                                                          checked={this.state.merchantBranchSettings.autoKitchen}></Checkbox>

                                            </AvGroup>
                                        </Col>

                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="firstName">
                                                    <Translate contentKey="productfirstName">Auto Inside
                                                        Delivery?&nbsp;</Translate>
                                                </Label>

                                                <Checkbox
                                                    onChange={(e) => this.setChecked("autoInsideDelivery", e.checked)}
                                                    name="autoInsideDelivery"
                                                    disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                    value={this.state.merchantBranchSettings.autoInsideDelivery}
                                                    checked={this.state.merchantBranchSettings.autoInsideDelivery}></Checkbox>

                                            </AvGroup>
                                        </Col>

                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="firstName">
                                                    <Translate contentKey="productfirstName">Auto
                                                        Dispatch?&nbsp;</Translate>
                                                </Label>

                                                <Checkbox
                                                    onChange={(e) => this.setChecked("autoDispatch", e.checked)}
                                                    name="autoDispatch"
                                                    disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                    value={this.state.merchantBranchSettings.autoDispatch}
                                                    checked={this.state.merchantBranchSettings.autoDispatch}></Checkbox>

                                            </AvGroup>
                                        </Col>

                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="firstName">
                                                    <Translate contentKey="productfirstName">Auto Outside
                                                        Delivery?&nbsp;</Translate>
                                                </Label>

                                                <Checkbox
                                                    onChange={(e) => this.setChecked("autoOutsideDelivery", e.checked)}
                                                    name="autoOutsideDelivery"
                                                    disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                    value={this.state.merchantBranchSettings.autoOutsideDelivery}
                                                    checked={this.state.merchantBranchSettings.autoOutsideDelivery}></Checkbox>

                                            </AvGroup>
                                        </Col>


                                    </Row>
                                </Fieldset>


                            </Col>


                            <Col md={4}>
                                <Fieldset legend={"Order Notification Settings"}>
                                    <Row>
                                        <Col md={6}>
                                            <AvGroup>
                                                <Label for="lastName">
                                                    <Translate contentKey="merchantAccount.lastName">Notification Tunnel</Translate>
                                                </Label>
                                                <MultiSelect display="chip"
                                                             value={this.state.merchantBranchSettings.notificationTunnel}
                                                             name="notificationTunnel"
                                                             disabled={!this.state.merchantBranchSettingsSaveButtonRender}
                                                             onChange={this.__handleChangeMerchantBranchSettings}
                                                             optionLabel="name" options={ORDER_NOTIFICATION_TUNNEL_OPTIONS}

                                                />
                                            </AvGroup>
                                        </Col>



                                    </Row>

                                </Fieldset>

                            </Col>
                        </Row>


                        {!this.state.merchantBranchSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({merchantBranchSettingsSaveButtonRender: true});
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}
                        {this.state.merchantBranchSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.merchantBranchSettingsSave();
                                this.setState({merchantBranchSettingsSaveButtonRender: false});
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>}

                        {this.state.merchantBranchSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.merchantBranchSettingsTemp;
                                this.setState({
                                    merchantBranchSettings: Object.assign({}, obj1),
                                    merchantBranchSettingsSaveButtonRender: false
                                });

                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>}


                    </TabPanel>
                    <TabPanel header="EOD Settings">
                        {this.generalSettingsDetails()}
                    </TabPanel>

                    <TabPanel header="Payment Integration Settings">


                        <Row>

                            <Col md={12}>
                                <Fieldset legend={"Payment Settings"}>
                                    <Row>
                                        <Col md={3}>
                                            <AvGroup>
                                                <Label for="lastName">
                                                    <Translate
                                                        contentKey="merchantAccount.lastName">Provider</Translate>
                                                </Label>
                                                <AvField
                                                    type="select"
                                                    className="form-control"
                                                    name="provider"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    value={this.state.merchantBranchSettings.provider}
                                                    onChange={this.__handleChangeMerchantBranchSettings}>
                                                    <option value='' key=''/>
                                                    {PAYMENT_INTEGRATION_PROVIDER.map(element => (
                                                        <option value={element.value} key={element.value}>
                                                            {element.name}
                                                        </option>))}
                                                </AvField>

                                            </AvGroup>
                                        </Col>

                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="tax.tradingName">Payment Page</Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="jsUrl"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    validate={{
                                                        maxLength: {
                                                            value: 255,
                                                            errorMessage: "You can enter up to 255 characters in this field."
                                                        }
                                                    }}
                                                    value={this.state.merchantBranchSettings.jsUrl}
                                                    onChange={this.__handleChangeMerchantBranchSettings}/>

                                            </AvGroup>
                                        </Col>


                                        <Col md="3">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="tax.tradingName">Base Url</Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="baseUrl"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    validate={{
                                                        maxLength: {
                                                            value: 255,
                                                            errorMessage: "You can enter up to 255 characters in this field."
                                                        }
                                                    }}
                                                    value={this.state.merchantBranchSettings.baseUrl}
                                                    onChange={this.__handleChangeMerchantBranchSettings}/>

                                            </AvGroup>
                                        </Col>

                                        <Col md="12">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="tax.tradingName">Merchant Url/Vendor
                                                        Name</Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="merchantUrl"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    validate={{
                                                        maxLength: {
                                                            value: 255,
                                                            errorMessage: "You can enter up to 255 characters in this field."
                                                        }
                                                    }}
                                                    value={this.state.merchantBranchSettings.merchantUrl}
                                                    onChange={this.__handleChangeMerchantBranchSettings}/>

                                            </AvGroup>
                                        </Col>

                                        <Col md="12">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="tax.tradingName">Jwt/Key</Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="jwt"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    validate={{
                                                        maxLength: {
                                                            value: 4000,
                                                            errorMessage: "You can enter up to 400 characters in this field."
                                                        }
                                                    }}
                                                    value={this.state.merchantBranchSettings.jwt}
                                                    onChange={this.__handleChangeMerchantBranchSettings}/>

                                            </AvGroup>
                                        </Col>

                                        <Col md="12">
                                            <AvGroup>
                                                <Label for="tradingName">
                                                    <Translate contentKey="tax.tradingName">Password</Translate>
                                                </Label>
                                                <AvField
                                                    type="text"
                                                    className="form-control"
                                                    name="password"
                                                    disabled={!this.state.paymentSettingsSaveButtonRender}
                                                    validate={{
                                                        maxLength: {
                                                            value: 4000,
                                                            errorMessage: "You can enter up to 400 characters in this field."
                                                        }
                                                    }}
                                                    value={this.state.merchantBranchSettings.password}
                                                    onChange={this.__handleChangeMerchantBranchSettings}/>

                                            </AvGroup>
                                        </Col>

                                    </Row>

                                </Fieldset>

                            </Col>


                        </Row>


                        {!this.state.paymentSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.setState({paymentSettingsSaveButtonRender: true});
                            }}>
                                <Translate contentKey="entity.action.add">Edit</Translate>
                            </Button>}
                        {this.state.paymentSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                this.merchantBranchSettingsSave();
                                this.setState({paymentSettingsSaveButtonRender: false});
                            }}>
                                <Translate contentKey="entity.action.add">Save</Translate>
                            </Button>}

                        {this.state.paymentSettingsSaveButtonRender &&
                            <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                                let obj1 = this.state.paymentSettingsTemp;
                                this.setState({
                                    paymentSettings: Object.assign({}, obj1),
                                    paymentSettingsSaveButtonRender: false
                                });

                            }}>
                                <Translate contentKey="entity.action.cancel">Cancel</Translate>
                            </Button>}


                    </TabPanel>


                    <TabPanel header={"Mail Settings"}>
                        <MailSettingsSave id={this.state.merchantBranch.id}></MailSettingsSave>
                    </TabPanel>
                </TabView>
            </Fieldset>
        </div>)
    }

    generalSettingsDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Report Settings">
                <Row>
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">EOD Email
                                            Address</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="email"
                                        disabled={!this.state.generalSettingsSaveButtonRender}
                                        validate={{
                                            minLength: {
                                                "value": 5, "errorMessage": "Not Valid Email"
                                            }, maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.merchantBranchSettings.email}
                                        onChange={this.__handleChangeMerchantBranchSettings}/>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Auto End Of Day</Translate>
                                    </Label>
                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="isAutoEndOfDay"
                                        disabled={!this.state.generalSettingsSaveButtonRender}
                                        value={this.state.merchantBranchSettings.isAutoEndOfDay}
                                        onChange={this.__handleChangeMerchantBranchSettings}>
                                        {YESNO.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">End Of Day Hour</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="endOfDayHour"
                                        disabled={!this.state.generalSettingsSaveButtonRender}
                                        value={this.state.merchantBranchSettings.endOfDayHour}
                                        onChange={this.__handleChangeMerchantBranchSettings}>
                                        <option value='' key=''/>
                                        {END_OF_DAY_TIME.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                        </Row>
                    </Col>
                </Row>


            </Fieldset>

            {this.state.generalSettingsSaveButtonRender == false &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.setState({generalSettingsSaveButtonRender: true});
                }}>
                    <Translate contentKey="entity.action.add">Edit</Translate>
                </Button>}

            {this.state.generalSettingsSaveButtonRender &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    this.merchantBranchSettingsSave();
                }}>
                    <Translate contentKey="entity.action.add">Save</Translate>
                </Button>
            }

            {this.state.generalSettingsSaveButtonRender &&
                <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                    let obj1 = this.state.merchantBranchSettingsTemp;
                    this.setState({
                        merchantBranchSettings: Object.assign({}, obj1), generalSettingsSaveButtonRender: false
                    });
                }}>
                    <Translate contentKey="entity.action.cancel">Cancel</Translate>
                </Button>
            }


        </div>)
    };

    setChecked = (name, value) => {
        let merchantBranchSettings = this.state.merchantBranchSettings;
        merchantBranchSettings[name] = value;
        this.setState({merchantBranchSettings: merchantBranchSettings});
    }


    merchantCredentialAndLicenseList() {
        return (this.state.merchantBranch.id != '' && this.state.pageMode == PAGE_MODE.UPDATE && <div>


            <Fieldset legend="Licenses & Credentials & Credits">
                <TabView activeIndex={this.state.activeIndex1}
                         onTabChange={(e) => this.setState({activeIndex1: e.index})}>
                    <TabPanel header="Licenses">
                        <DataTableAll ref={(elem) => this.refDataTable = elem} fields={LicenseColumn.fields}
                                      objectName={LicenseColumn.name}
                                      data={this.state.licenseList}
                                      selectionMode="single" onDataSelect={this.onDataSelect}
                                      loading={this.state.loading}/>

                        <Dialog visible={this.state.displayDialog}
                                header={"Licenses"}
                                onHide={() => this.setState({displayDialog: false})}
                                style={{width: '30vw', height: '35vw'}}>

                            <LicenseSave refreshDataTable={this.refreshDataTable}
                                         ref={(elem) => this.refModelSave = elem}
                                         closeDisplayDialog={this.closeDisplayDialog}/>
                        </Dialog>
                    </TabPanel>

                    <TabPanel header="Credentials">
                        <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                            let data = {
                                merchantBranchId: this.state.merchantBranch.id,
                            };


                            this.onDataSelectCredentials(data);
                        }}>
                            <Translate contentKey="entity.action.add">Add</Translate>
                        </Button>

                        <DataTableAll ref={(elem) => this.refModelCredentialsSave = elem}
                                      fields={BranchCredentialsColumn.fields}
                                      objectName={BranchCredentialsColumn.name}
                                      data={this.state.credentialList}
                                      rows={3}
                                      selectionMode="single" onDataSelect={this.onDataSelectCredentials}
                                      loading={this.state.loading}/>

                        <Dialog visible={this.state.displayDialogCredentials}
                                header={"Credentials"}
                                onHide={() => this.setState({displayDialogCredentials: false})}
                                style={{width: '30vw', height: '35vw'}}>

                            <CredentialsSave refreshDataTable={this.refreshDataTableCredentials}
                                             ref={(elem) => this.refModelCredentialsSave = elem}
                                             closeDisplayDialog={this.closeDisplayDialogCredentials}/>
                        </Dialog>
                    </TabPanel>

                    <TabPanel header="Credits">


                        <Button color="outline-primary" style={{marginBottom: '5px'}} onClick={() => {
                            let data = {
                                merchantBranchId: this.state.merchantBranch.id,
                            };


                            this.onDataSelectCredit(data);
                        }}>
                            <Translate contentKey="entity.action.add">Add</Translate>
                        </Button>

                        <label htmlFor="productItem">&nbsp;&nbsp;Total Active Credit:&nbsp;&nbsp;
                            {this.state.totalCredit}</label>

                        <label htmlFor="productItem">&nbsp;&nbsp;Total Remaining Credit:&nbsp;&nbsp;
                            {this.state.totalCreditUsed}</label>

                        <ButtonSearch icon="pi pi-refresh" style={{marginBottom: '5px', 'float': 'right'}}
                                      onClick={() => {
                                          this.refreshDataTableCredit();
                                      }}>

                        </ButtonSearch>

                        <DataTableAll ref={(elem) => this.refModelCreditList = elem} fields={CreditColumn.fields}
                                      objectName={CreditColumn.name}
                                      data={this.state.creditList}
                                      rows={3}
                                      selectionMode="single" onDataSelect={this.onDataSelectCredit}
                                      loading={this.state.loading}/>

                        <Dialog visible={this.state.displayDialogCredit}
                                header={"Credits"}
                                onHide={() => this.setState({displayDialogCredit: false})}
                                style={{width: '30vw', height: '35vw'}}>


                            <CreditSave refreshDataTable={this.refreshDataTableCredit}
                                        ref={(elem) => this.refModelCreditSave = elem}
                                        closeDisplayDialog={this.closeDisplayDialogCredit}/>
                        </Dialog>
                    </TabPanel>


                </TabView>
            </Fieldset>
        </div>)
    }

    merchantLicenseList() {
        return (this.state.merchantBranch.id != '' && this.state.pageMode == PAGE_MODE.UPDATE && <div>
            <Fieldset legend="Licenses">
                <DataTableAll ref={(elem) => this.refDataTable = elem} fields={LicenseColumn.fields}
                              objectName={LicenseColumn.name}
                              data={this.state.licenseList}
                              selectionMode="single" onDataSelect={this.onDataSelect}
                              loading={this.state.loading}/>

                <Dialog visible={this.state.displayDialog}
                        header={"Credentials"}
                        onHide={() => this.setState({displayDialog: false})} style={{width: '30vw', height: '35vw'}}>

                    <LicenseSave refreshDataTable={this.refreshDataTable} ref={(elem) => this.refModelSave = elem}
                                 closeDisplayDialog={this.closeDisplayDialog}/>
                </Dialog>

            </Fieldset>
        </div>)
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    closeDisplayDialogCredentials = () => {
        this.setState({displayDialogCredentials: false});
    }

    closeDisplayDialogCredit = () => {
        this.setState({displayDialogCredit: false});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true
        });

    }

    onDataSelectCredentials = (data) => {
        this.refModelCredentialsSave.setData(Object.assign({}, data));
        this.setState({
            displayDialogCredentials: true
        });

    }

    onDataSelectCredit = (data) => {
        this.refModelCreditSave.setData(Object.assign({}, data));
        this.setState({
            displayDialogCredit: true
        });

    }

    saveBranch = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.merchantBranch.sector) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.tradingName) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.vatNo) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.businessName) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.domainName)) {
            return toast.warn("Please fill the required fields!");
        } else {
            if (GeneralUtils.isNullOrEmpty(this.state.merchantBranch.id)) {
                this.merchantBranchService.createBranch(this.state.merchantAccountId, this.state.merchantBranch)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            let merchantResponse = response;
                            this.licenseService.findByBranchId(merchantResponse.id).then(response => this.setState({
                                licenseList: response
                            }));

                            this.currentUserService.getCurrentAdminOrMemberUser().then(response => {
                                this.setState({
                                    branchDetailsSaveButtonRender: false,
                                    branchDetailsContactSaveButtonRender: this.state.pageMode == PAGE_MODE.CREATE ? true : false,
                                    merchantBranch: merchantResponse,
                                    merchantBranchTemp: Object.assign({}, merchantResponse),
                                    disabledContactDetailsTab: false,
                                    activeIndex: 1
                                });

                            });
                        }


                    });
            } else {
                this.merchantBranchService.update(this.state.merchantBranch)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            this.setState({
                                branchDetailsSaveButtonRender: false,
                                merchantBranch: response,
                                merchantBranchTemp: Object.assign({}, response)
                            })
                        }

                    });
            }
        }

    }

    saveMerchantBranchContact = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.merchantBranch.firstName) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.lastName) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.mobilePhone) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.emailAddress)) {
            return toast.warn("Please fill the required fields!");
        } else {
            var contactData = {
                firstName: this.state.merchantBranch.firstName,
                lastName: this.state.merchantBranch.lastName,
                mobilePhone: this.state.merchantBranch.mobilePhone,
                emailAddress: this.state.merchantBranch.emailAddress
            };
            this.merchantBranchService.updateBranchContact(this.state.merchantBranch.id, contactData)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            branchDetailsContactSaveButtonRender: false,
                            branchDetailsAddressSaveButtonRender: this.state.pageMode == PAGE_MODE.CREATE ? true : false,
                            disabledAddressDetailsTab: false,
                            activeIndex: this.state.pageMode == PAGE_MODE.CREATE ? 2 : this.state.activeIndex

                        });
                    }
                });
        }

    }

    saveMerchantBranchAddress = () => {

        if (GeneralUtils.isNullOrEmpty(this.state.merchantBranch.address1) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.city) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.postCode) || GeneralUtils.isNullOrEmpty(this.state.merchantBranch.country)) {
            return toast.warn("Please fill the required fields!");
        } else {
            var addressData = {
                address1: this.state.merchantBranch.address1,
                address2: this.state.merchantBranch.address2,
                address3: this.state.merchantBranch.address3,
                address4: this.state.merchantBranch.address4,
                city: this.state.merchantBranch.city,
                postCode: this.state.merchantBranch.postCode,
                country: this.state.merchantBranch.country,
                state: this.state.merchantBranch.state
            };

            this.merchantBranchService.updateBranchAddress(this.state.merchantBranch.id, addressData)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                        this.setState({
                            branchDetailsAddressSaveButtonRender: false, pageMode: PAGE_MODE.UPDATE

                        });
                    }
                });
        }


    }

    __handleChangeBranch = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let merchantBranch = this.state.merchantBranch;
        merchantBranch[e.target.name] = value;
        this.setState({merchantBranch: merchantBranch});
    }

    __handleChangeMerchantBranchSettings = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let merchantBranchSettings = this.state.merchantBranchSettings;
        merchantBranchSettings[e.target.name] = value;
        if (e.target.name == "isAutoEndOfDay" && value == "false") {
            merchantBranchSettings["endOfDayHour"] = null;
        }

        if (e.target.name == "provider") {
            if (value == "DOJO") {
                if (GeneralUtils.isProductionEnvironment()) {
                    merchantBranchSettings["jsUrl"] = 'https://web.e.connect.paymentsense.cloud/assets/js/client.js';
                    merchantBranchSettings["baseUrl"] = 'https://e.connect.paymentsense.cloud';
                    merchantBranchSettings["merchantUrl"] = '';
                    merchantBranchSettings["jwt"] = '';
                    merchantBranchSettings["password"] = '';
                } else {
                    merchantBranchSettings["jsUrl"] = 'https://web.e.test.connect.paymentsense.cloud/assets/js/client.js';
                    merchantBranchSettings["baseUrl"] = 'https://e.test.connect.paymentsense.cloud';
                    merchantBranchSettings["merchantUrl"] = 'www.testurl.com';
                    merchantBranchSettings["jwt"] = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJjb25uZWN0LWUtZGV2QGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imh0dHBzOi8vZS50ZXN0LmNvbm5lY3QucGF5bWVudHNlbnNlLmNsb3VkIiwiZXhwIjoyNDMyMTI4NTUzLCJpYXQiOjE2NzUyNjQ1NTMsInN1YiI6ImNvbm5lY3QtZS1kZXZAYXBwc3BvdC5nc2VydmljZWFjY291bnQuY29tIiwiYXBpS2V5IjoiMTY1YmI1ZWEtMTAxNS00MWM3LTk2N2MtOGUwYzk0YWY2MzYxIiwiZW1haWwiOiJjb25uZWN0LWUtZGV2QGFwcHNwb3QuZ3NlcnZpY2VhY2NvdW50LmNvbSJ9.MbKDhb9J3oP4wnmpcWWIv_Av9FAaxegkiQLtOLOc5DaPdSTvA5B6Wkprej4R1RnyU3-0rKrdbOYs1Xrik34Iq8W7rmY4OrzNgoIr-T1TRmNxj3BSF-Dx17-__1oOwY8soE2E7X5y7kvZ0_yY1_ArX6PzQhjLpfNUSKYjN969akjZ4L93bZC_C6-xJ6EKiO6PI-MdFMjyKoggxWuS5hO8dIT_Xm9lVJsxSz7_Zh2imtNLZZTpzKeADkDjx6yznuWRMMaFe2pQkTBM4h3ESyZvexa_bEzlNLyNT3v1vHDQXAfTw3uhlpPM-T3HrYLw9qpdLTn6jrhACGZ-3mfWL6oI7A';
                    merchantBranchSettings["password"] = '';
                }


            } else if (value == "OPAYO") {
                if (GeneralUtils.isProductionEnvironment()) {
                    merchantBranchSettings["jsUrl"] = 'https://live.opayo.eu.elavon.com/api/v1/js/sagepay.js';
                    merchantBranchSettings["baseUrl"] = 'https://live.opayo.eu.elavon.com/api';
                    merchantBranchSettings["merchantUrl"] = '';
                    merchantBranchSettings["jwt"] = '';
                    merchantBranchSettings["password"] = '';
                } else {
                    merchantBranchSettings["jsUrl"] = 'https://sandbox.opayo.eu.elavon.com/api/v1/js/sagepay.js';
                    merchantBranchSettings["baseUrl"] = 'https://sandbox.opayo.eu.elavon.com/api';
                    merchantBranchSettings["merchantUrl"] = 'computarttest';
                    merchantBranchSettings["jwt"] = 'xPIQ2jgnNv97wXrmUbCO3h2d493KTcVw0ZZNKN1HGvl2HRJGD8';
                    merchantBranchSettings["password"] = '1lAr2nvdoZPDBYed7J4HyBa9hnb0l9Ws8NIBsH9EnUmPv5v5LwtabgUW89ZVs60dS';
                }

            } else {
                merchantBranchSettings["jsUrl"] = '';
                merchantBranchSettings["baseUrl"] = '';
            }

        }

        this.setState({merchantBranchSettings: merchantBranchSettings});
    }

    __handleChangePageSettings = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let pageSettings = this.state.pageSettings;
        pageSettings[e.target.name] = value;
        this.setState({pageSettings: pageSettings});
    }

    headerUploader = (event) => {
        let header = this.refUploadInputHeader.files;
        if (GeneralUtils.isNullOrEmpty(header) || GeneralUtils.isNullOrEmpty(header.length)) {
        } else {
            this.currentUserService.getCurrentAdminOrMemberUser().then(response => this.setState({
                renderHeaderShowButton: true
            }));
        }
    }

    footerUploader = (event) => {
        let footer = this.refUploadInputFooter.files;
        if (GeneralUtils.isNullOrEmpty(footer) || GeneralUtils.isNullOrEmpty(footer.length)) {
        } else {
            this.currentUserService.getCurrentAdminOrMemberUser().then(response => this.setState({
                renderFooterShowButton: true
            }));
        }
    }

    logoUploader = (event) => {
        let logo = this.refUploadInputLogo.files;
        if (GeneralUtils.isNullOrEmpty(logo) || GeneralUtils.isNullOrEmpty(logo.length)) {
        } else {
            this.currentUserService.getCurrentAdminOrMemberUser().then(response => this.setState({
                renderLogoShowButton: true
            }));
        }
    }
}

