import React from 'react';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {Alert, Button, Col, Row} from 'reactstrap';
import Translate from "../../shared/language/Translate";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import MerchantUserSecurityService from "../../shared/service/MerchantUserSecurityService";

export default class PasswordResetInitMerchant extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            recaptchaToken: null,
            email: null
        }
        this.merchantUserSecurityService = new MerchantUserSecurityService();

    }


    componentDidMount() {
        if (this.reCAPTCHA) {
            this.reCAPTCHA.reset();
        }
    }

    onChange = (recaptchaToken) => {
        this.setState({recaptchaToken: recaptchaToken});
    }

    render() {
        return (
            <div>
                <Alert color="warning">
                    <p>
                        <Translate contentKey="reset.request.messages.info">Enter the e-mail address used when
                            registering. Follow the instructions sent to your e-mail to reset your password.</Translate>
                    </p>
                </Alert>
                <AvForm>
                    <AvField
                        name="email"
                        label='Email'
                        placeholder='Email Address'
                        value={this.state.email} onChange={this.__handleChange}
                        type="email"
                        validate={{
                            required: {value: true, errorMessage: 'This field is required.'},
                            minLength: {value: 5, errorMessage: 'This field can contain at least 5 characters.'},
                            maxLength: {value: 255, errorMessage: 'The e-mail address cannot exceed 255 characters.'},
                            email: {errorMessage: 'Email field is not available.'}
                        }}
                    />

                    <div>
                        <Row>

                            <Col>
                                <br/>
                                <Button color="primary" onClick={this.handleValidSubmit} className="captchaButton"
                                        disabled={false}>
                                    <Translate contentKey="entity.action.add">Send</Translate>
                                </Button>

                            </Col>
                        </Row>
                    </div>

                </AvForm>
            </div>
        );
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let state = this.state;
        state[e.target.name] = value;
        this.setState(state);
    }

    handleValidSubmit = () => {

        if (!GeneralUtils.isNullOrEmpty(this.state.email)) {
            let data = {
                email: this.state.email,
                domainName: "merchant"
            };
            this.merchantUserSecurityService.resetPasswordInit(data);
        }

    };
}
