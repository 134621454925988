import React from 'react';
import './OrderListPrimeReact.css';
import GeneralUtils from "../../shared/utils/GeneralUtils";
import 'react-tabs-scrollable/dist/rts.css'
import {Card} from 'primereact/card';
import MarketPlaceService from "../../shared/service/MarketPlaceService";
import {Fieldset} from "primereact/fieldset";
import {Col, Row} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {SERVER_URL} from "../../shared/utils/Constants";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";
import Loading from "../../shared/component/others/Loading";

export default class MarketPlace extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            branches: [],
            favoriteBranchesOfUser: [],
            loading: false,
            searchText: "",
            itemSub: null
        };
        this.marketPlaceService = new MarketPlaceService();
    }

    componentDidMount = () => {
        this.refreshList();

    }


    isBranchFavoriteOfUser(id) {
        var result = false;
        let favoriteBranchesOfUser = this.state.favoriteBranchesOfUser;

        for (var i = 0; i < favoriteBranchesOfUser.length; i++) {
            if (favoriteBranchesOfUser[i] == id) {
                result = true;
            }
        }
        return result;
    }

    renderCardList(isFavorite) {
        let form = [];

        let height = "22em";
        let width = "30em";
        let margin = "10px";
        if (GeneralUtils.isMobile()) {
            height = "22em";
            width = "30em";
            margin = "auto"
        }

        if (this.state.branches != undefined && !GeneralUtils.isNullOrEmpty(this.state.branches) && this.state.branches.length > 0) {
            this.state.branches.forEach(itemSub => {
                let isBranchFavoriteOfUser = this.isBranchFavoriteOfUser(itemSub.id);
                if ((isFavorite == false && isBranchFavoriteOfUser == false) || (isFavorite == true && isBranchFavoriteOfUser == true) || (isFavorite == null)) {
                    form.push(
                        <div onClick={(e) => {
                            this.clickItem(itemSub, isBranchFavoriteOfUser)
                        }
                        } id={itemSub.id} align={"center"} style={{
                            marginRight: margin,
                            marginLeft: margin,
                            marginBottom: "10px",
                            marginTop: "10px",
                            cursor: "pointer",
                            borderLeft: "6px solid #00bcd4 !important;"
                        }}>
                            <Card style={{
                                borderLeft: "none",
                                height: height,
                                width: width,
                                maxHeight: height,
                                maxWidth: width
                            }} title={itemSub.tradingName}

                                  header={this.renderCardImage(itemSub)} className="md:w-5rem">
                                <p className="m-0">
                                    {this.renderAddress(itemSub)}  <p></p>
                                    {this.renderFavIcon(itemSub, isBranchFavoriteOfUser)} <p></p>
                                    {this.renderPostCodeKmAround(itemSub)} <p></p>
                                    {this.renderServiceableAreas(itemSub)} <p></p>
                                    {this.renderHours(itemSub)}
                                </p>
                            </Card></div>);
                }


            });
        }
        return form;
    }

    renderServiceableAreas(item) {
        return <h7>{item.serviceableAreas}</h7>;
    }

    renderAddress(item) {
        return <h7>{item.displayAddressString}</h7>;
    }

    renderHours(item) {
        return <h7>{item.openAndCloseHours}</h7>;
    }

    renderPostCodeKmAround(item) {
        return <h7>{item.postCodeAround}</h7>;
    }

    renderCardImage(item) {
        let form = [];
        form.push(
            <div className="image-container">
                <img
                    style={{
                        maxHeight: '60px',
                        maxWidth: '60px',
                        height: '60px',
                        width: '60px',
                        alignContent: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        margin: "10px 10px 10px 10px",
                        position: "relative",

                    }}
                    src={GeneralUtils.convertForImageSource(item.content, item.fileType, item.fileName)}
                    onError={(e) => e.target.src = "assets/layout/images/white.png"}
                    alt={item.name}/>


            </div>
        );
        return form;
    }

    render() {
        let form = [];
        form.push(this.renderList());
        form.push(this.state.loading && <Loading></Loading>)

        return form;
    }

    renderList() {
        let form = [];
        form.push(<ModalConfirm header='Warning' ok='OK' onOkClick={this.clickFavIcon}
                                onCancelClick={() => this.setState({displayShowStatusConfirmDialog: false})}
                                show={this.state.displayShowStatusConfirmDialog}
                                message={this.state.noticeResponse}/>);
        form.push(this.renderSearchText());

        if (GeneralUtils.isAuthenticated()) {
            form.push(<Fieldset legend="Your Favorites">
                <Row>
                    {this.renderCardList(true)}
                </Row>
            </Fieldset>);
            form.push(<Fieldset legend="Other Stores">
                <Row>
                    {this.renderCardList(false)}
                </Row>
            </Fieldset>);
        } else {
            form.push(<Fieldset legend="Stores">
                <Row>
                    {this.renderCardList(null)}
                </Row>
            </Fieldset>);
        }


        return form;
    }


    refreshList() {
        this.setState({loading: true});


        this.marketPlaceService.findAll().then(response => {
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                this.setState({
                    branches: response,
                    loading: false
                });

            } else {
                this.setState({
                    branches: [],
                    loading: false
                });
            }
        });

        if (GeneralUtils.isAuthenticated() && !GeneralUtils.isNullOrEmpty(localStorage.getItem("appMode")) && localStorage.getItem("appMode") == "member")  {
            this.marketPlaceService.findFavoriteBranchesOfUser().then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {

                    this.setState({
                        favoriteBranchesOfUser: response,
                    });
                }
            })
        }


    }

    renderFavIcon(itemSub, isBranchFavoriteOfUser) {
        if (GeneralUtils.isAuthenticated()) {
            if (isBranchFavoriteOfUser) {
                return <i
                    onClick={(e) => this.showChangeStatusConfirmDialog(e, itemSub, "Do you want to remove from your favorites?")}
                    className="pi pi-star"
                    style={{
                        fontSize: "2rem",
                        float: "right",
                        top: "-140px",
                        right: "-10px",
                        position: "relative",
                        margin: "0px"
                    }}></i>
            } else {
                return <i
                    onClick={(e) => this.showChangeStatusConfirmDialog(e, itemSub, "Do you want to add to your favorites?")}
                    className="pi pi-star-o"
                    style={{
                        fontSize: "2rem",
                        float: "right",
                        top: "-140px",
                        right: "-10px",
                        position: "relative",
                        margin: "0px"
                    }}></i>
            }
        }

    }

    renderSearchText() {

        let form = [];
        form.push(<div
            style={{
                top: "-30px",
                position: "relative", marginBottom: "15px", backgroundColor: '#FFFFFF'
            }}>

            <div align={"center"}>


                <InputText value={this.state.searchText}
                           style={{borderRadius: "10px", width: "240px"}}
                           placeholder="Search"
                           onChange={this.__handleChangeSearchText}/>
            </div>
        </div>);


        return form;

    }

    showChangeStatusConfirmDialog = (e, itemSub, text) => {
        this.setState({
            displayShowStatusConfirmDialog: true,
            noticeResponse: text,
            itemSub: itemSub
        });
        e.stopPropagation();
    }

    clickFavIcon = () => {
        var makeFavorite = true;
        let itemSub = this.state.itemSub;
        if (!GeneralUtils.isNullOrEmpty(itemSub)) {
            if (this.isBranchFavoriteOfUser(itemSub.id)) {
                makeFavorite = false;
            }

            this.marketPlaceService.updateFavoriteBranch(itemSub.id, makeFavorite)
                .then((response) => {
                    this.refreshList();
                });
        }


    }

    clickItem(itemSub, isBranchFavoriteOfUser) {

        this.setState({loading: true});
        if (GeneralUtils.isAuthenticated() && !isBranchFavoriteOfUser) {
            this.marketPlaceService.updateFavoriteBranch(itemSub.id, true)
                .then((response) => {
                    if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    }
                });
        }
        //localStorage.removeItem("basketBssId");
        localStorage.setItem("merchantBranchId", itemSub.id);
        this.setState({loading: false});
        window.location.href = "/" + itemSub.domainName

    }

    __handleChangeSearchText = async (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        this.setState({searchText: value});
        if (!GeneralUtils.isNullOrEmpty(value) && value.length > 3) {
            this.setState({branches: []});

            const response = (await this.marketPlaceService.findAllBySearchText(value));
            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.status)) {
                this.setState({
                    branches: response,
                    loading: false
                });
            } else {
                this.setState({
                    branches: [],
                    loading: false
                });
            }
        } else {
            this.refreshList();

        }

    }


}