import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class PageSettingsService extends Service {
    constructor() {
        super();
        this.baseURL = 'crm/merchant/pagesettings';
    }

    async createPageSettings(header, footer, logo, data) {
        let formData = new FormData();
        formData.append("data", JSON.stringify(data));
        formData.append("files", header[0]);
        formData.append("files", footer[0]);
        formData.append("files", logo[0]);
        return fetch(SERVER_URL + this.baseURL, {
            crossDomain: true,
            method: 'POST',
            headers: {Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: formData
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updatePageSettings(id, data) {
        let formData = new FormData();
        formData.append("data", JSON.stringify(data));
        return fetch(SERVER_URL + this.baseURL +"/" +  id, {
            crossDomain: true,
            method: 'PUT',
            headers: {Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: formData
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });

    }

    async uploadDocument(id, formData) {
        return fetch(SERVER_URL + this.baseURL + "/document/upload/" + id, {
            crossDomain: true,
            method: 'PUT',
            headers: {Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
            body: formData
        }).then(response => {
            let result = response.clone();
            //GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async download(id, type) {
        return fetch(SERVER_URL + this.baseURL + '/document/download/' + id + "/" + type, {
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }
    async getImage(branchId, type) {
        return fetch(SERVER_URL + this.baseURL + '/image/' + branchId + "/" + type, {
            headers: {'Content-Type': 'application/json'}
        })
            .then(response => response.json())
            .catch(error => {
                GeneralUtils.notification(error);
            });
    }


}