import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class SystemParamsService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/systemparams';
    }

    getSystemParams() {
        return fetch(SERVER_URL + this.baseURL  , {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            //GeneralUtils.notification(error);
        });
    }


}