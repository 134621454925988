import React, {Component} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {AppMenu} from './AppMenu';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';
import '../layout/layout.css';
import GeneralUtils from "../../utils/GeneralUtils";
import HashRouterCompMember from "../HashRouterCompMember";
import {AppTopbarMember} from "./AppTopbarMember";
import {AppInlineProfileMember} from "./AppInlineProfileMember";
import LicenseService from "../../service/LicenseService";
import MerchantBranchService from "../../service/MerchantBranchService";

import {toast} from "react-toastify";
import {Button, Col, Row} from "reactstrap";
import Loading from "../../component/others/Loading";
import {AppFooterMember} from "./AppFooterMember";

const defaultAddressInfo = {
    addressText: '', contactPhone: ''
};

class AppMasterMember extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            layoutMode: 'static',
            layoutColorMode: 'light',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            licenseCheck: true,
            menu: null,
            displayDialog: false,
            addressInfo: defaultAddressInfo

        };

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.licenseService = new LicenseService();
        this.merchantBranchService = new MerchantBranchService();

    }

    componentDidMount = () => {
        let domainName = GeneralUtils.getDomainName();
        //let domainName = "tansas";

        if (GeneralUtils.isNullOrEmpty(domainName) || domainName=="admin") {
            localStorage.setItem("merchantBranchId", -1);
        } else {
            this.merchantBranchService.getBranchIdByDomainName(domainName).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type) && response != 0) {
                    localStorage.setItem("merchantBranchId", response);

                    this.licenseService.getLicenses(localStorage.getItem("merchantBranchId")).then(response => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            localStorage.setItem("WEB_ORDER_LICENSE", response.hasWebOrderLicense);
                            localStorage.setItem("WEB_MENU_LICENSE", response.hasWebMenuLicense);
                            localStorage.setItem("TABLE_ORDER_LICENSE", response.hasTableOrderLicense);

                            if (!GeneralUtils.isNullOrEmpty(localStorage.getItem("tableNo"))) {
                                if (response.hasTableOrderLicense || response.hasWebMenuLicense) {
                                    this.setState({licenseCheck: true});
                                } else {
                                    this.setState({licenseCheck: false});
                                    return toast.warn("Service Temporarily Unavailable");
                                }
                            } else {
                                if (response.hasWebOrderLicense || response.hasWebMenuLicense) {
                                    this.setState({licenseCheck: true});
                                } else {
                                    this.setState({licenseCheck: false});
                                    return toast.warn("Service Temporarily Unavailable");
                                }
                            }

                        }
                    });


                    this.merchantBranchService.getStoreInfo(localStorage.getItem("merchantBranchId")).then(response => {
                            if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                                this.setState({addressInfo: response});

                            }
                        }
                    )
                }


            });


        }


    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            } else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        } else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }

        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
        setTimeout(() => {
        }, 500);
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1400;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    startLoading = () => {
        this.setState({loading: true});
    }

    setChecked = (name, value) => {
        this.setState({openRefundPolicyDialog: value});
    }

    render() {
        let styleMember = GeneralUtils.isMobile() ? '0px' : '10px';
        let wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });
        let sidebarClassName = classNames("layout-sidebar", {'layout-sidebar-dark': this.state.layoutColorMode === 'dark'});

        if (this.state.licenseCheck) {
            let marginRight = "5%"
            if(GeneralUtils.isMobile()){
                marginRight = "0"
            }
            return (
                <div className={wrapperClass} style={{  marginRight: marginRight}} >
                    <AppTopbarMember onToggleMenu={this.onToggleMenu} logout={this.props.logout}
                                     startLoading={this.startLoading} />

                    <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>

                        <div className="layout-sidebar-scroll-content">
                            <div className="layout-logo">

                            </div>
                            <AppInlineProfileMember/>
                            <AppMenu model={this.state.menu} onMenuItemClick={this.onMenuItemClick}/>

                        </div>

                    </div>
                    <div className="layout-main" style={{marginRight: styleMember,minHeight:window.innerHeight/100*82+'px'}}>
                        {this.state.loading && <Loading/>}
                        <HashRouterCompMember/>
                        <Row>
                            <Col md={11}>
                            </Col>
                            <Col md={1}>

                            </Col>
                        </Row>

                    </div>
                    {localStorage.getItem("merchantBranchId") != -1 && window.location.href.indexOf("marketplace") <=0 &&
                        <AppFooterMember></AppFooterMember>
                    }




                </div>

            )
        } else {
            return '';
        }


    }
}

AppMasterMember.propTypes = {
    login: PropTypes.func,
};


export default AppMasterMember;
