import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import Loading from "../../shared/component/others/Loading";
import DataTable from "../../shared/component/dataTable/DataTable";
import SaleReportColumn from "../../shared/component/dataTable/columns/SaleReportColumn.json";
import {Button, Col, Row} from "reactstrap";
import Translate from "../../shared/language/Translate";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import AdminZXReportService from "../../shared/service/AdminZXReportService";
import ModalConfirm from "../../shared/component/dialog/ModalConfirm";

export default class AdminSaleReportDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: null,
            merchantBranchId:null,
            loading: false
        };
        this.adminZXReportService = new AdminZXReportService();
    }

    componentDidMount() {

    }


    render() {
        return (<div>
            <AvForm>
                {this.renderReport()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    exportPdf = () => {
        this.refDataTable.exportPdf();
    }

    sendEmail = () => {
        this.refDataTable.sendEmail();
    }

    renderReport() {
        return (<div>

            <Fieldset legend="Sale Report">

                <Row>
                    <Col md="12">
                        <Button color="outline-primary" style={{float: 'left',   width: '140px',marginRight: '5px', marginBottom:"10px"}}
                                onClick={this.calculate} className="pi pi-file-pdf">
                            &nbsp;
                            <Translate contentKey="entity.action.csv">Calculate Again</Translate>
                        </Button>


                    </Col>
                    <ModalConfirm header='Warning' ok='OK' onOkClick={this.calculateAgain}
                                  onCancelClick={() => this.setState({displayCalculateConfirmDialog: false})}
                                  show={this.state.displayCalculateConfirmDialog}
                                  message={this.state.noticeResponse}/>
                </Row>
                <Row>
                    <Col md="12">
                        <DataTable fields={SaleReportColumn.fields}
                                   refresh={false}
                                   ref={(elem) => this.refDataTable = elem}
                                   objectName={SaleReportColumn.name}
                                   rows={100}
                                   reportName="Sale Report"
                                   paginator={false}
                                   dataURL={!GeneralUtils.isNullOrEmpty(this.state.id) ? "reporting/admin/zxreport/" + this.state.id +"/" +this.state.merchantBranchId :null}
                                   selectionMode="single"
                                   loading={this.state.loading}/>

                    </Col>
                </Row>


            </Fieldset>

            <Button color="outline-primary" style={{marginRight: '5px', height: '30px'}}
                    onClick={() => {
                        this.props.closeDisplayDialog();
                    }}>
                <Translate contentKey="entity.action.add">Close</Translate>
            </Button>


        </div>);
    }

    calculate = (data) => {
        this.setState({
            displayCalculateConfirmDialog: true,
            noticeResponse: "Are you sure to calculate sale report again?",
            reportId: data.id
        });
    }

    calculateAgain = () => {
        if (!GeneralUtils.isNullOrEmpty(this.state.id)) {
            this.setState({
                loading: true,
            });
            this.adminZXReportService.calculateAgain(this.state.id)
                .then((response) => {
                    this.setState({
                        loading: false,
                        reportId: null
                    });
                    this.closeDisplayDialog()

                });
        }
    }
    setData = (data) => {
        this.setState({
            id: data.id,
            merchantBranchId : data.merchantBranchId,
        }, () => {
            this.refDataTable.refresh(data.id,data.merchantBranchId);
        });

    }

    closeDisplayDialog = () => {
        this.props.closeDisplayDialog();
    }

}

