import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantMenuService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/merchant';
    }

    async findMenus() {
        return fetch(SERVER_URL + this.baseURL + "/menu", {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getPrivileges() {
        return fetch(SERVER_URL + this.baseURL + "/privilege", {
            headers: { 'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}