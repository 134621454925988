import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";
import ServiceMember from "./ServiceMember";

export default class MemberUserSecurityService extends ServiceMember {
    constructor() {
        super();
        this.baseURL = 'security/auth/member';
    }

    async checkEmail(email) {
        return fetch(SERVER_URL + this.baseURL + "/checkemail/" + email, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getGoogleClientId() {
        return fetch(SERVER_URL + this.baseURL + "/google/clientid" , {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    login(data) {
        return fetch(SERVER_URL + this.baseURL + "/login", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    getTokens(authCode) {
        return fetch(SERVER_URL + this.baseURL + "/token/code/" + authCode, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    register(data) {
        return fetch(SERVER_URL + this.baseURL + "/register", {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    resetPasswordInit(data){
        fetch(SERVER_URL + 'security/auth' +'/password/reset/init', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    resetPasswordFinish(data){
        fetch(SERVER_URL + 'security/auth' +'/password/reset/finish', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    refreshToken() {
        let data = {
            refreshToken: GeneralUtils.getRefreshTokenFromCookie()
        };
        return fetch(SERVER_URL +  this.baseURL + "/token/refresh" , {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}