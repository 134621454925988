import React from 'react';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import {Fieldset} from "primereact/fieldset";
import MyPreviousOrdersColumn from "../../shared/component/dataTable/columns/MyPreviousOrdersColumn.json";
import GeneralUtils from "../../shared/utils/GeneralUtils";
import Loading from "../../shared/component/others/Loading";
import DataTable from '../../shared/component/dataTable/DataTable';
import Dialog from "../../shared/component/dialog/Dialog";
import ItemsDetail from "../storemanagement/ItemsDetail";
import DataTableMember from "../../shared/component/dataTable/DataTableMember";

export default class MyOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }

    componentDidMount() {
    }

    render() {
        return (<div>
            <AvForm>
                {this.ordersList()}
            </AvForm>
            {this.state.loading && <Loading/>}
        </div>);
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

    onDataSelect = (data) => {
        this.refModelSave.setData(Object.assign({}, data));
        this.setState({
            displayDialog: true
        });

    }


    ordersList() {

        return (<div class="p-col-12">
            <Fieldset legend="My Orders">

                <DataTableMember ref={(elem) => this.refDataTable = elem} fields={MyPreviousOrdersColumn.fields}
                           objectName={MyPreviousOrdersColumn.name}
                           dataURL="dispatchio/basket/previousorders"
                           emptyMessage="Not any order"
                           responsive={false}
                           paginator={true}
                           onDataSelect={this.onDataSelect}
                           selectionMode="single"
                           loading={this.state.loading}/>

                <Dialog visible={this.state.displayDialog}
                        header={"Order Details"}
                        onHide={() => this.setState({displayDialog: false})}
                        style={GeneralUtils.getDialogStyleForDialog()}>

                    <ItemsDetail closeDisplayDialog={this.closeDisplayDialog} ref={(elem) => this.refModelSave = elem}
                    />
                </Dialog>
            </Fieldset>

        </div>)
    };


}

