import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class AdminUserService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/user';
    }


    async findAllByGroupName(groupName) {
        return fetch(SERVER_URL + this.baseURL + "/group/"+ groupName, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async updateUser(userId, data) {
        return fetch(SERVER_URL + this.baseURL + "/" + userId, {
            crossDomain: true,
            method: 'put',
            headers: {
                'Content-Type': 'application/json',
                Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())
            },
            body: JSON.stringify(data)
        }).then(response => {
                let result = response.clone();
                GeneralUtils.notification(response);
                return GeneralUtils.responseToJson(result);
            }
        ).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}