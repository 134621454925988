import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class LicenseService extends Service {
    constructor() {
        super();
        this.baseURL = 'crm/merchant/license';
    }

    async checkLicensesBranchId(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/check/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getLicenses(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/getLicenses/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}