import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";
import ServiceMember from "./ServiceMember";

export default class ComplaintSuggestionService extends ServiceMember {
    constructor() {
        super();
        this.baseURL = 'security/user/member/complaintSuggestion';
    }



}