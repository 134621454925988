import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class NotificationService extends Service {
    constructor() {
        super();
        this.baseURL = 'utility/notification/mailSettings';
    }


    async sendEmail(to) {
        return fetch(SERVER_URL + this.baseURL + "/sendemail/" + to, {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())},
        }).then(response => {

        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}