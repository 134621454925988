import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class WebMenuMemberService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/webmenumember';
    }

    async getActiveWebMenuShortcutsForMemberByBranchId(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/status/active/member/shortcuts/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notificationPayNoAttention(error);
        });
    }

    async getWebMenuItemsByParentId( merchantBranchId, parentId) {
        return fetch(SERVER_URL + this.baseURL + "/status/active/member/" +  merchantBranchId + "/" + parentId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    async getActiveSubWebMenuShortcutsForMemberByParentId( merchantBranchId,parentId) {
        return fetch(SERVER_URL + this.baseURL + "/status/active/member/shortcuts/" + merchantBranchId  +"/parentid/" +  parentId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findBySearchText(branchId, name) {
        return fetch(SERVER_URL + this.baseURL + "/status/active/member/" + branchId + "/name/" + name, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }


}