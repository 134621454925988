import React from 'react';
import {Button, Col, Label, Row} from 'reactstrap';
import {AvForm, AvGroup} from 'availity-reactstrap-validation';
import Translate from "../../shared/language/Translate";
import GeneralUtils from '../../shared/utils/GeneralUtils';
import AvField from "../../shared/component/form/AvField";
import {Fieldset} from "primereact/fieldset";
import {toast} from "react-toastify";
import {Checkbox} from 'primereact/checkbox';
import DepartmentService from "../../shared/service/DepartmentService";
import TaxService from "../../shared/service/TaxService";
import Dialog from "../../shared/component/dialog/Dialog";
import DepartmentTransfer from "./DepartmentTransfer";
import {PRICE_CALCULATION_METHOD} from "../../shared/utils/Constants";

const defaultDepartment = {
    id: '',
    name: '',
    status: 'ACTIVE',
    tax: null,
    ageRestricted: false,
    excludePromotion: false,
    shelfEdgeLabelRequired: false,
    excludeServiceCharge: false,
    autoCreateMenu: false,
    profitMargin: null
};
export default class DepartmentSave extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            department: defaultDepartment,
            departmentTemp: defaultDepartment,
            departmentDetailsSaveButtonRender: true,
            defaultStatus: 'ACTIVE',
            taxList: [],
            departmentList: []
        }
        this.departmentService = new DepartmentService();
        this.taxService = new TaxService();
    }

    componentDidMount() {

        let id = window.location.href.split('?')[1];
        if (!GeneralUtils.__isValueEmpty(id)) {
            this.departmentService.findById(id).then(response => {
                this.setState({
                    department: response,
                    departmentDetailsSaveButtonRender: false,
                    departmentTemp: Object.assign({}, response)
                });
            });
        } else {
            let obj1 = Object.assign({}, defaultDepartment);
            this.setState({
                department: obj1, departmentDetailsSaveButtonRender: true, departmentTemp: Object.assign({}, obj1)
            });
        }

        this.taxService.findAll().then(response => this.setState({taxList: response}));
    }

    render() {
        return (<div >
            <AvForm>
                {this.departmentDetails()}
            </AvForm>
        </div>);
    }

    setChecked = (name, value) => {
        let department = this.state.department;
        if (name == "excludePromotion" && value == true) {
            this.departmentService.hasConnectedAnyMixAndMatch(this.state.department.id).then(response => {
                if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                    department[name] = value;
                    this.setState({department: department});
                }
            });
        } else {
            department[name] = value;
            this.setState({department: department});
        }
    }

    departmentDetails() {

        return (<div class="p-col-12">
            <Fieldset legend="Department Details">
                <Row>
                    <Col md="12">

                        <Dialog visible={this.state.displayDialog}
                                header={""}
                                onHide={() => this.setState({displayDialog: false})}
                                style={GeneralUtils.getDialogStyleMerchantForDialog()}>

                            <DepartmentTransfer ref={(elem) => this.refModelSave = elem}
                                                closeDisplayDialog={this.closeDisplayDialog}/>
                        </Dialog>


                        <Row>
                            <Col md="6">

                                <AvGroup>
                                    <Label for="tradingName">
                                        <Translate contentKey="department.tradingName">Name</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>
                                    <AvField
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        disabled={!this.state.departmentDetailsSaveButtonRender}
                                        validate={{
                                            maxLength: {
                                                value: 255,
                                                errorMessage: "You can enter up to 255 characters in this field."
                                            }
                                        }}
                                        value={this.state.department.name}
                                        onChange={this.__handleChange}/>

                                </AvGroup>

                            </Col>
                        </Row>
                        <Row>

                            <Col md="6">


                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Tax</Translate>
                                        <span style={{color: 'red'}}> (*)</span>
                                    </Label>

                                    <AvField
                                        type="select"
                                        disabled={!this.state.departmentDetailsSaveButtonRender}
                                        className="form-control"
                                        name="tax"
                                        value={this.state.department.tax}
                                        onChange={this.__handleChange}>

                                        <option value='' key=''/>
                                        {this.state.taxList.map(element => (<option value={element.id} key={element.id}>
                                            {element.name}
                                        </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>
                        </Row>

                        <Row>
                            <Col md="6">


                                <AvGroup>
                                    <Label for="mobilePhone">
                                        <Translate contentKey="contactManagement.mobilePhone">Profit Margin</Translate>
                                    </Label>

                                    <AvField
                                        type="number"
                                        disabled={!this.state.departmentDetailsSaveButtonRender}
                                        className="form-control"
                                        name="profitMargin"
                                        value={this.state.department.profitMargin}
                                        onChange={this.__handleChange}>
                                    </AvField>

                                </AvGroup>

                            </Col>
                        </Row>

                        <Row>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="department.firstName">Age Restricted?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Translate>
                                    </Label>

                                    <Checkbox onChange={(e) => this.setChecked("ageRestricted", e.checked)}
                                              name="ageRestricted"
                                              disabled={!this.state.departmentDetailsSaveButtonRender}
                                              value={this.state.department.ageRestricted}
                                              checked={this.state.department.ageRestricted}></Checkbox>


                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="lastName">
                                        <Translate contentKey="department.lastName">Exclude Promotion?&nbsp;</Translate>
                                    </Label>
                                    <Checkbox onChange={(e) => this.setChecked("excludePromotion", e.checked)}
                                              name="excludePromotion"
                                              disabled={!this.state.departmentDetailsSaveButtonRender}
                                              value={this.state.department.excludePromotion}
                                              checked={this.state.department.excludePromotion}></Checkbox>
                                </AvGroup>
                            </Col>

                        </Row>

                        <Row>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="department.emailAddress">Shelf Edge Label
                                            Required?&nbsp;</Translate>
                                    </Label>
                                    <Checkbox onChange={(e) => this.setChecked("shelfEdgeLabelRequired", e.checked)}
                                              name="shelfEdgeLabelRequired"
                                              disabled={!this.state.departmentDetailsSaveButtonRender}
                                              value={this.state.department.shelfEdgeLabelRequired}
                                              checked={this.state.department.shelfEdgeLabelRequired}></Checkbox>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <Label for="mobilePhone" style={{color: 'red'}}>
                                    <Translate contentKey="contactManagement.mobilePhone">Warning! Products which are
                                        connected to this department will not queue for shelf edge label</Translate>
                                </Label>
                            </Col>


                        </Row>


                        <Row>


                            <Col md="3">
                                <AvGroup>
                                    <Label for="companyNo">
                                        <Translate contentKey="department.companyNo">Exclude Service
                                            Charge?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Translate>
                                    </Label>
                                    <Checkbox onChange={(e) => this.setChecked("excludeServiceCharge", e.checked)}
                                              name="excludeServiceCharge"
                                              disabled={!this.state.departmentDetailsSaveButtonRender}
                                              value={this.state.department.excludeServiceCharge}
                                              checked={this.state.department.excludeServiceCharge}></Checkbox>
                                </AvGroup>
                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="autoCreateMenu">
                                        <Translate contentKey="department.autoCreateMenu">Auto Create
                                            Menu?&nbsp;&nbsp;&nbsp;</Translate>
                                    </Label>
                                    <Checkbox onChange={(e) => this.setChecked("autoCreateMenu", e.checked)}
                                              name="autoCreateMenu"
                                              disabled={!this.state.departmentDetailsSaveButtonRender}
                                              value={this.state.department.autoCreateMenu}
                                              checked={this.state.department.autoCreateMenu}></Checkbox>
                                </AvGroup>
                            </Col>
                        </Row>

                        <Row>
                            <Col md="3">

                                <AvGroup>
                                    <Label for="emailAddress">
                                        <Translate contentKey="merchantAccount.emailAddress">Calculation Method</Translate>
                                    </Label>

                                    <AvField
                                        type="select"
                                        className="form-control"
                                        name="priceCalculationMethod"
                                        disabled={!this.state.departmentDetailsSaveButtonRender}
                                        value={this.state.department.priceCalculationMethod}
                                        onChange={this.__handleChange}>
                                        {PRICE_CALCULATION_METHOD.map(element => (
                                            <option value={element.value} key={element.value}>
                                                {element.name}
                                            </option>))}
                                    </AvField>

                                </AvGroup>

                            </Col>

                            <Col md="3">
                                <AvGroup>
                                    <Label for="firstName">
                                        <Translate contentKey="productfirstName">Calculation Rate</Translate>
                                    </Label>

                                    <AvField
                                        name="priceCalculationRate"
                                        type="number"
                                        disabled={!this.state.departmentDetailsSaveButtonRender || this.state.department.priceCalculationMethod == "NO_CALCULATION"}
                                        value={this.state.department.priceCalculationRate}
                                        onChange={this.__handleChange}
                                        className="table-responsive"

                                    />


                                </AvGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {GeneralUtils.checkPrivilege("post_product/department") && this.state.departmentDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.department.id) &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.setState({departmentDetailsSaveButtonRender: true});
                    }}>
                        <Translate contentKey="entity.action.add">Edit</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/department") && this.state.departmentDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveDepartment();
                    }}>
                        <Translate contentKey="entity.action.add">Save</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/department") && this.state.departmentDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        this.saveAndNextDepartment();
                    }}>
                        <Translate contentKey="entity.action.add">SaveAndNext</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("delete_product/department/_id/transferDepartmentId/_transferDepartmentId") && this.state.departmentDetailsSaveButtonRender == false && !GeneralUtils.isNullOrEmpty(this.state.department.id) &&

                    <Button color="outline-danger" style={{marginRight: '5px'}} onClick={() => {
                        this.refModelSave.setDepartmentId(this.state.department.id);
                        this.setState({
                            displayDialog: true
                        });
                    }}>
                        <Translate contentKey="entity.action.add">Delete</Translate>
                    </Button>}

                {GeneralUtils.checkPrivilege("post_product/department") && this.state.departmentDetailsSaveButtonRender &&
                    <Button color="outline-primary" style={{marginRight: '5px'}} onClick={() => {
                        let obj1 = this.state.departmentTemp;
                        if (GeneralUtils.isNullOrEmpty(obj1.id)) {
                            window.history.back();
                        } else {
                            this.setState({
                                department: Object.assign({}, obj1), departmentDetailsSaveButtonRender: false
                            });
                        }
                    }}>
                        <Translate contentKey="entity.action.cancel">Cancel</Translate>
                    </Button>}
            </Fieldset>


        </div>)
    };

    onDataSelect = (data) => {
            this.departmentService.findById(data.id).then(response => {
                this.setState({
                    department: response,
                    departmentDetailsSaveButtonRender: false,
                    departmentTemp: Object.assign({}, response)
                });

            });

    }


    saveAndNextDepartment = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.department.name) || GeneralUtils.isNullOrEmpty(this.state.department.tax)) {
            return toast.warn("Please fill the required fields!");
        } else if (!GeneralUtils.isNullOrEmpty(this.state.department.priceCalculationMethod) && (this.state.department.priceCalculationMethod !="NO_CALCULATION") && GeneralUtils.isNullOrEmpty(this.state.department.priceCalculationRate)) {
            return toast.warn("Please fill the rate field!");
        }else {
            if (!GeneralUtils.isNullOrEmpty(this.state.department.id)) {
                this.departmentService.update(this.state.department)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            let obj1 = Object.assign({}, defaultDepartment);
                            this.setState({
                                department: obj1, departmentTemp: Object.assign({}, obj1)

                            });
                        }
                    });
            } else {
                this.departmentService.create(this.state.department)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            let obj1 = Object.assign({}, defaultDepartment);
                            this.setState({
                                department: obj1, departmentTemp: Object.assign({}, obj1)

                            });
                        }
                    });
            }
        }

    }


    saveDepartment = () => {
        if (GeneralUtils.isNullOrEmpty(this.state.department.name) || GeneralUtils.isNullOrEmpty(this.state.department.tax)) {
            return toast.warn("Please fill the required fields!");
        }
        else if (!GeneralUtils.isNullOrEmpty(this.state.department.priceCalculationMethod) && (this.state.department.priceCalculationMethod !="EMPTY") && GeneralUtils.isNullOrEmpty(this.state.department.priceCalculationRate)) {
                return toast.warn("Please fill the rate field!");
        }
        else {
            if (!GeneralUtils.isNullOrEmpty(this.state.department.id)) {
                this.departmentService.update(this.state.department)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            window.location.href = "#departmentList";
                        }
                    });
            } else {
                this.departmentService.create(this.state.department)
                    .then((response) => {
                        if (!GeneralUtils.isNullOrEmpty(response) && GeneralUtils.isNullOrEmpty(response.type)) {
                            window.location.href = "#departmentList";
                        }
                    });
            }
        }

    }

    addNew() {
        let obj1 = Object.assign({}, defaultDepartment);
        this.setState({
            department: obj1, departmentDetailsSaveButtonRender: true, departmentTemp: Object.assign({}, obj1)
        });
    }

    __handleChange = (e) => {
        let value = e.target.parsedValue !== undefined ? e.target.parsedValue : e.target.value;
        let department = this.state.department;
        department[e.target.name] = value;
        if (e.target.name == "priceCalculationMethod" && value == "EMPTY") {
            department["priceCalculationRate"] = null;
        }

        this.setState({department: department});
    }

    closeDisplayDialog = () => {
        this.setState({displayDialog: false});
    }

}

