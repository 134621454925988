import Service from './Service';

export default class ProductGroupService extends Service {
    constructor() {
        super();
        this.baseURL = 'product/productgroup';
    }




}