import Service from './Service';
import {
    SERVER_URL,
} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class ProductDataWareHouseService extends Service {
    constructor() {
        super();
        this.baseURL = 'product';
    }


    async productHasImage(barcode,name) {
        return fetch(SERVER_URL + this.baseURL + "/productwarehouse/hasImage/barcode/" + barcode + "/name/"+ name, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }




}