import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class MerchantBranchSettingsService extends Service {
    constructor() {
        super();
        this.baseURL = 'crm/merchantbranchsettings';
    }

    async findByBranchId(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/merchantBranchId/" + branchId, {
            headers: {'X-Softrame-BranchId' : localStorage.getItem("merchantBranchId"),'Content-Type': 'application/json', Authorization: (await GeneralUtils.getJwtFromCookieOrTillService())}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async findByBranchIdForMember(branchId) {
        return fetch(SERVER_URL + this.baseURL + "/member/merchantBranchId/" + branchId, {
            headers: {'Content-Type': 'application/json'}
        }).then(response => response.json()).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async getPaymentSettingsInfo(merchantBranchId) {
        return fetch(SERVER_URL + this.baseURL + "/paymentsettings/" + merchantBranchId, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    async isStoreClosed(merchantBranchId) {
        return fetch(SERVER_URL + this.baseURL + "/isstoreclosed/branchId/" + merchantBranchId, {
            crossDomain: true,
            method: 'get',
            headers: {'Content-Type': 'application/json'}
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response, true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

}