import Service from './Service';
import {SERVER_URL} from "../utils/Constants";
import GeneralUtils from "../utils/GeneralUtils";

export default class AdminUserSecurityService extends Service {
    constructor() {
        super();
        this.baseURL = 'security/auth';
    }

    refreshToken() {
        let data = {
            refreshToken: GeneralUtils.getRefreshTokenFromCookie()
        };
        return fetch(SERVER_URL +  this.baseURL + "/token/refresh" , {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response,true);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }


    resetPasswordInit(data){
        fetch(SERVER_URL + this.baseURL +'/password/reset/init', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }

    resetPasswordFinish(data){
        fetch(SERVER_URL + this.baseURL +'/password/reset/finish', {
            crossDomain: true,
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        }).then(response => {
            let result = response.clone();
            GeneralUtils.notification(response);
            return GeneralUtils.responseToJson(result);
        }).catch(error => {
            GeneralUtils.notification(error);
        });
    }



}